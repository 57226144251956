import {
  Button,
  DateInput,
  OutlinedSelect,
  SearchBox,
  SelectOptionType,
} from '@octano/global-ui';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import useQuery, { Parameters } from '../../../../hooks/useQuery';
import { useForm } from 'react-hook-form';
import { prefix } from '..';
import { formatDate } from '../../../../utils/date';

export interface ListsDictionary {
  [index: string]: SelectOptionType[];
}
interface Props {
  filters: ListsDictionary;
  defaultValues: { documentType: string };
}

export default function SearchFilters({ filters, defaultValues }: Props) {
  const { t } = useTranslation();

  const { getParam, setParams } = useQuery();
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      searchDoctype: {
        label: '',
        value: '',
      },
      searchRut: getParam('searchRut') || '',
      searchFromDate: getParam('searchFromDate') || '',
      searchToDate: getParam('searchToDate') || '',
    },
  });

  const setSearchDoctypeByDefault = useCallback(() => {
    if (filters?.documentTypes?.length > 0) {
      const [defaultDoctype] = filters?.documentTypes.filter(
        (doctypeOpt: SelectOptionType) =>
          doctypeOpt.value === defaultValues.documentType,
      );
      setValue('searchDoctype', {
        label: defaultDoctype.label,
        value: defaultValues.documentType,
      });
    }
  }, [defaultValues, filters.documentTypes]);

  useEffect(() => {
    setSearchDoctypeByDefault();
  }, [defaultValues, setSearchDoctypeByDefault]);

  const onSubmit = useCallback(
    (values) => {
      const valuesNotEmpty: Parameters = {};
      if (values.searchDoctype) {
        valuesNotEmpty.searchDoctype = values.searchDoctype.value;
      }
      if (values.searchRut) {
        valuesNotEmpty.searchRut = values.searchRut;
      }
      if (values.searchFromDate) {
        valuesNotEmpty.searchFromDate = formatDate(
          values.searchFromDate,
          'YYYY-MM-DD',
        );
      }
      if (values.searchToDate) {
        valuesNotEmpty.searchToDate = formatDate(
          values.searchToDate,
          'YYYY-MM-DD',
        );
      }
      setParams(valuesNotEmpty);
    },
    [setParams],
  );

  const onClear = useCallback(() => {
    setParams({ searchDoctype: defaultValues.documentType });
    setValue('searchRut', '');
    setValue('searchFromDate', '');
    setValue('searchToDate', '');
    setSearchDoctypeByDefault();
  }, [defaultValues, setSearchDoctypeByDefault, setParams, setValue]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="d-flex align-items-center justify-content-start pb-2">
        <Col xs={12} md={4} lg={3} xl={2}>
          <OutlinedSelect
            name="searchDoctype"
            label={t(`${prefix}.documentType`)}
            placeholder={t(`${prefix}.searchDocumentType`)}
            isClearable={true}
            options={filters.documentTypes}
            control={control}
          />
        </Col>
        <Col xs={12} md={4} lg={3} xl={2}>
          <SearchBox
            name="searchRut"
            label={t(`${prefix}.rut`)}
            placeholder={t(`${prefix}.searchRut`)}
            control={control}
          />
        </Col>
        <Col xs={12} md={4} lg={3} xl={2}>
          <DateInput
            name="searchFromDate"
            label={t(`${prefix}.dateFrom`)}
            control={control}
          />
        </Col>
        <Col xs={12} md={4} lg={3} xl={2}>
          <DateInput
            name="searchToDate"
            label={t(`${prefix}.dateTo`)}
            control={control}
          />
        </Col>
        <Col xs={12} md={2} lg={2} xl={2} className="pt-2">
          <Button
            type="submit"
            text={t(`common.actions.search`)}
            size="md"
            fullwidth
          />
        </Col>
        <Col xs={12} md={2} lg={2} xl={2} className="pt-2">
          <Button
            text={t(`common.actions.clear`)}
            size="md"
            fullwidth
            outlined
            onClick={onClear}
          />
        </Col>
      </Row>
    </Form>
  );
}
