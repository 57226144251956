import { Button, OutlinedSelect } from '@octano/global-ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import useQuery from '../../../../hooks/useQuery';
import { useForm } from 'react-hook-form';
import { ListsDictionary } from '..';

interface Props {
  filters: ListsDictionary;
}

export default function SearchFilters({ filters }: Props) {
  const { t } = useTranslation();

  const { getParam, setParams } = useQuery();
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      year: {
        label: getParam('year') || new Date().getFullYear(),
        value: getParam('year') || new Date().getFullYear(),
      },
    },
  });

  const onSubmit = useCallback(
    (values) => {
      setParams({ year: values.year.value });
    },
    [setParams],
  );

  const onClear = useCallback(() => {
    setParams({ year: '' });
    setValue('year', {
      label: new Date().getFullYear(),
      value: new Date().getFullYear(),
    });
  }, [setParams, setValue]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="d-flex align-items-center justify-content-end pb-2">
        <Col xs={12} md={5}>
          <OutlinedSelect
            name="year"
            label="Filtrar por año"
            isClearable={false}
            control={control}
            options={filters.years}
          />
        </Col>
        <Col xs={12} md={3} className="pt-2">
          <Button
            type="submit"
            text={t(`common.actions.search`)}
            size="md"
            fullwidth
          />
        </Col>
        <Col xs={12} md={3} className="pt-2">
          <Button
            text={t(`common.actions.clear`)}
            size="md"
            fullwidth
            outlined
            onClick={onClear}
          />
        </Col>
      </Row>
    </Form>
  );
}
