import { addToast, Button, SelectOptionType, Table } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import {
  getAnnualFinancialSummary,
  getFilterAnnualFinancialSummary,
} from '../../../api/requests/financial-flow';
import { getProject } from '../../../api/requests/projects';
import TableEmptyContent from '../../../components/TableEmptyContent';
import { PathsLayouts } from '../../../config/routes';
import { useLoading } from '../../../hooks/useLoading';
import useQuery from '../../../hooks/useQuery';
import { AnnualSummary } from '../../../types/financial-flow';
import { Project } from '../../../types/projects';
import ProjectInfo from './parts/ProjectInfo';
import SearchFilters from './parts/SearchFilters';
import useTableColumns from './parts/useTableColumns';

export interface ListsDictionary {
  [index: string]: SelectOptionType[];
}

export default function AnnualSummaryTable() {
  const prefix = `views.financialFlow.tableAnnualSummary`;

  const { t } = useTranslation();
  const history = useHistory();
  const { getParam } = useQuery();
  const { loading, setLoading } = useLoading();
  const [summary, setSummary] = useState<AnnualSummary[]>([]);
  const [projectData, setProjectData] = useState<Partial<Project>[]>([]);
  const [filtersList, setFiltersList] = useState<ListsDictionary>({
    years: [],
  });

  const { projectCode } = useParams<{ projectCode: string }>();
  const year = Number(getParam('year')) || new Date().getFullYear();

  /** Get project data */
  const getProjectData = useCallback(async () => {
    const { data, error } = await getProject(projectCode);

    if (error || !data) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`common.terms.unexpectedError`),
      });
    }

    setProjectData(data ? [data] : []);
  }, [t, projectCode]);

  useEffect(() => {
    getProjectData();
  }, [getProjectData]);

  const viewDetailOnClick = useCallback(
    (month: string) => {
      history.push(
        `${PathsLayouts.financialFlow}/${projectCode}/annual-summary/monthly-detail?year=${year}&month=${month}`,
      );
    },
    [projectCode, year, history],
  );

  /** get table columns */
  const columns = useTableColumns({
    viewDetailOnClick,
  });

  /** get annual summary */
  const getAnnualSummary = useCallback(async () => {
    setLoading(true);

    const { data, error } = await getAnnualFinancialSummary(projectCode, year);

    if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`common.terms.unexpectedError`),
      });
      setLoading(false);
      return;
    }

    if (data) {
      setSummary(data);
    }
    setLoading(false);
  }, [t, setSummary, setLoading, projectCode, year]);

  useEffect(() => {
    getAnnualSummary();
  }, [getAnnualSummary]);

  /** Permite obtener la data para los filtros */
  const getDataForFilters = useCallback(async () => {
    const { data } = await getFilterAnnualFinancialSummary(projectCode);
    if (data) {
      const parsedYearsData = data.map((year: { year: string }) => {
        return { value: year.year, label: year.year };
      });

      setFiltersList({ years: parsedYearsData });
    }
  }, [projectCode, setFiltersList]);

  useEffect(() => {
    getDataForFilters();
  }, [getDataForFilters]);

  return (
    <>
      <Row className="d-flex align-items-center pb-2">
        <Col xs={12} md={6}>
          <Button
            icon="back"
            onClick={() => history.push(`${PathsLayouts.financialFlow}`)}
            outlined
            rounded
            size="sm"
            text={t(`common.actions.backToThePreviousStep`)}
          />
        </Col>

        <Col xs={12} md={6}>
          <SearchFilters filters={filtersList} />
        </Col>
      </Row>

      <ProjectInfo data={projectData} />

      <Row className="pt-5 pb-2">
        <Col sm={12} md={6}>
          <h5 className="text-primary font-weight-bold text-uppercase">
            {`${t(`${prefix}.title`)} ${year}`}
          </h5>
        </Col>
      </Row>

      <Table
        borderless
        striped
        columns={columns}
        data={summary}
        isLoadingResults={loading}
        noResultsText={
          <TableEmptyContent
            title={t(`${prefix}.emptyTitle`)}
            subtitle={t(`${prefix}.emptyDesc`)}
          />
        }
      />
    </>
  );
}
