import { Tab, TabPanel, TabPanels, Tabs, TabsList } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import DetailView from './parts/detailView';

export const prefix = `views.collectionManagement.tableSummaryList`;

export const SUMMARY_LIST_TABS_ROUTES = {
  CURRENTS_TAB_ID: 'currents',
  PAYMENTS_TAB_ID: 'payments',
} as const;

const { CURRENTS_TAB_ID, PAYMENTS_TAB_ID } = SUMMARY_LIST_TABS_ROUTES;

export default function CollectionManagementSummaryList() {
  const { t } = useTranslation();
  const { projectCode } = useParams<{ projectCode: string }>();

  return (
    <>
      <Tabs>
        <TabsList>
          <Tab>{t(`${prefix}.${CURRENTS_TAB_ID}.tabTitle`)}</Tab>
          <Tab id={PAYMENTS_TAB_ID}>
            {t(`${prefix}.${PAYMENTS_TAB_ID}.tabTitle`)}
          </Tab>
        </TabsList>

        <TabPanels>
          <TabPanel>
            <DetailView idTab={CURRENTS_TAB_ID} projectCode={projectCode} />
          </TabPanel>
          <TabPanel id={PAYMENTS_TAB_ID}>
            <DetailView idTab={PAYMENTS_TAB_ID} projectCode={projectCode} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}
