import { addToast, Table } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import {
  getProjectsAssignedForMonthlyResult,
  ParametersProjectsAssignedPagination,
} from '../../../api/requests/monthly-result';
import TableEmptyContent from '../../../components/TableEmptyContent';
import { useLoading } from '../../../hooks/useLoading';
import { usePagination } from '../../../hooks/usePagination';
import useQuery from '../../../hooks/useQuery';
import useUserState from '../../../hooks/useUserState';
import { AssignedProjectReport } from '../../../types/projects';
import SearchFilters from './parts/SearchFilters';
import useTableColumns from './parts/useTableColumns';

export default function MonthlyResultAssignProjectsTable() {
  const prefix = `views.monthlyResult.tableAssignedProjects`;

  const { t } = useTranslation();
  const { userData } = useUserState();
  const { getParam } = useQuery();

  const { loading, setLoading } = useLoading();
  const { page, pagination, setCurrentPage, setPagination, itemsPerPage } =
    usePagination();
  const [projects, setProjects] = useState<AssignedProjectReport[]>([]);

  // user id
  const id = userData?.id;

  /** get list project */
  const columns = useTableColumns();

  const getProjectList = useCallback(
    async (pageNumber?: number) => {
      setLoading(true);

      const currentPage = pageNumber || page;
      let params: ParametersProjectsAssignedPagination = {
        page: (currentPage - 1).toString(),
        itemsPerPage: itemsPerPage.toString(),
      };

      const currentSearch = getParam('search');
      if (currentSearch) {
        params.search = currentSearch;
      }

      const { data, error } = await getProjectsAssignedForMonthlyResult(
        id!,
        params,
      );

      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`common.terms.unexpectedError`),
        });
        setLoading(false);
        return;
      }

      if (data) {
        setProjects(data.data);
        setPagination({
          totalItems: data.total,
          itemsPerPage: itemsPerPage,
          totalPages: data.totalPages,
          currentPage: currentPage,
          onChangePage: (pageNumber: number) => setCurrentPage(pageNumber),
        });
      }
      setLoading(false);
    },
    [
      t,
      setProjects,
      setLoading,
      setCurrentPage,
      setPagination,
      getParam,
      page,
      itemsPerPage,
      id,
    ],
  );

  useEffect(() => {
    getProjectList();
  }, [getProjectList]);

  return (
    <>
      <SearchFilters />

      <Row className="pt-4 pb-2">
        <Col sm={12} md={6}>
          <h6>{t(`${prefix}.title`)}</h6>
        </Col>
      </Row>
      <Table
        borderless
        striped={false}
        columns={columns}
        data={projects}
        pagination={pagination}
        isLoadingResults={loading}
        noResultsText={
          <TableEmptyContent
            title={t(`${prefix}.emptyTitle`)}
            subtitle={t(`${prefix}.emptyDesc`)}
          />
        }
      />
    </>
  );
}
