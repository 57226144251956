import { ColumnTable, Button } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AssignedProjectReport } from '../../../../types/projects';

export default function useTableColumns() {
  const { t } = useTranslation();

  const columns: ColumnTable<AssignedProjectReport>[] = useMemo(() => {
    return [
      {
        columnName: 'projectCode',
        thClassName: 'text-center',
        tdClassName: 'text-center fw-700',
        headerText: t(`common.terms.projectCode`),
      },
      {
        columnName: 'name',
        headerText: t(`common.terms.projectName`),
      },
      {
        columnName: 'action',
        thClassName: 'text-center',
        tdClassName: 'text-center fw-700',
        headerText: t(`common.terms.action`),
        cellFormat: ({ row }) => {
          return (
            <Button
              size="sm"
              fullwidth
              text={t(`common.actions.consult`)}
              onClick={() => null}
            />
          );
        },
      },
    ];
  }, [t]);

  return columns;
}
