import { Button } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

interface Props {
  setIsOpen: (param: boolean) => void;
}

export default function PasswordRecoveryMailSuccess({ setIsOpen }: Props) {
  const { t } = useTranslation();
  const prefix = `views.account.recovery`;

  return (
    <Row>
      <Col xs={12} className="mb-4 d-flex justify-content-center">
        <p className="text-center mb-0 fs-18">
          {t(`${prefix}.successSubtitle`)}
        </p>
      </Col>
      <Button
        type="button"
        text={t(`common.actions.understood`)}
        onClick={() => {
          setIsOpen(false);
        }}
        fullwidth
      />
    </Row>
  );
}
