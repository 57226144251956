import { Table } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { MonthlySummary } from '../../../../types/financial-flow';
import { formatCurrency } from '../../../../utils/currency';

interface Props {
  data: MonthlySummary[];
}

export default function Summary({ data }: Props) {
  const { t } = useTranslation();
  const tariffFormatter = formatCurrency({});

  return (
    <Table
      borderless={false}
      color="transparent"
      size="small"
      columns={[
        {
          columnName: 'lastAmounth',
          headerText: t('common.terms.lastBalance'),
          thClassName: 'font-weight-bold text-uppercase',
          cellFormat: ({ row }) => {
            return tariffFormatter.format(Number(row.lastAmounth));
          },
        },
        {
          columnName: 'debit',
          headerText: t('common.terms.incomeOfTheMonth'),
          thClassName: 'font-weight-bold text-uppercase',
          cellFormat: ({ row }) => {
            return tariffFormatter.format(Number(row.debit));
          },
        },
        {
          columnName: 'credit',
          headerText: t('common.terms.outflowOfTheMonth'),
          thClassName: 'font-weight-bold text-uppercase',
          cellFormat: ({ row }) => {
            return tariffFormatter.format(Number(row.credit));
          },
        },
        {
          columnName: 'amounth',
          headerText: t('common.terms.balanceOfTheMonth'),
          thClassName: 'font-weight-bold text-uppercase',
          cellFormat: ({ row }) => {
            return tariffFormatter.format(Number(row.amounth));
          },
        },
      ]}
      data={data}
    />
  );
}
