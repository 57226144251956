import { Modal, Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { prefix } from '..';

interface Props {
  isOpen: boolean;
}

export default function ExportableLoading({ isOpen }: Props) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} toggle={() => null}>
      <div className="d-flex flex-column align-items-center">
        <Icon name="circle" className="spin" size={51} />
        <h1 className="text-dark fs-22 text-center">
          {t(`${prefix}.waitMinute`)}
        </h1>
        <p className="fs-18">{t(`${prefix}.fileIsBeingGenerated`)}</p>
      </div>
    </Modal>
  );
}
