import { SidenavCategoryType, SidenavLinkType } from '@octano/global-ui';
import { PermissionName } from '../types/auth';

export interface WithPermissions {
  requiredPermissions?: PermissionName[];
  allPermisionsRequired?: boolean;
}

export type LinkWithPermissions = SidenavLinkType & WithPermissions;

export type SubLinkWithPermissions = SidenavCategoryType['links'][number] &
  WithPermissions;

export type CategoryWithPermissions = Omit<SidenavCategoryType, 'links'> & {
  links: SubLinkWithPermissions[];
};

export type SidenavLinksWithPermissions = (
  | LinkWithPermissions
  | CategoryWithPermissions
)[];

export const SIDENAV_LINKS: LinkWithPermissions[] = [
  {
    path: '/maintainers/users',
    name: 'usersModule',
    icon: 'study_plan',
    requiredPermissions: [PermissionName.userShow],
  },
  {
    path: '/collection-management',
    name: 'collectionManagementModule',
    icon: 'pay_module',
    requiredPermissions: [PermissionName.collectionManagementShow],
  },
  {
    path: '/notes-receivable',
    name: 'notesReceivableModule',
    icon: 'certificates_requests',
    requiredPermissions: [PermissionName.notesReceivableShow],
  },
  {
    path: '/monthly-result',
    name: 'monthlyResultModule',
    icon: 'academic_offer',
    requiredPermissions: [PermissionName.monthlyResultShow],
  },
  {
    path: '/financial-flow',
    name: 'financialFlowModule',
    icon: 'admision',
    requiredPermissions: [PermissionName.financialShow],
  },
  {
    path: '/payment-status',
    name: 'paymentStatusModule',
    icon: 'bank_statement',
    requiredPermissions: [PermissionName.paymentStatusShow],
  },
];
