/**
 * Permite dar formato de moneda a un valor númerico
 */
export const formatCurrency = ({
  locale = 'es-CL',
  currency = 'CLP',
  symbol = true,
}: {
  locale?: string;
  currency?: string;
  symbol?: boolean;
}) => {
  return new Intl.NumberFormat(locale, {
    style: symbol ? 'currency' : undefined,
    currency: currency,
  });
};
