import { Modal } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import AccountContainer from '../AccountContainer';
import { useState } from 'react';
import PasswordRecoveryMailForm from './parts/PasswordRecoveryMailForm';
import PasswordRecoveryMailSuccess from './parts/PasswordRecoveryMailSuccess';

interface Props {
  isOpen: boolean;
  setIsOpen: (param: boolean) => void;
}

export default function PasswordRecoveryModal({ isOpen, setIsOpen }: Props) {
  const { t } = useTranslation();
  const prefix = `views.account.recovery`;
  const [recoverySuccess, setRecoverySuccess] = useState<boolean>(false);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => false}
      onClosed={() => {
        setRecoverySuccess(false);
      }}
    >
      <AccountContainer
        title={
          recoverySuccess ? t(`${prefix}.successTitle`) : t(`${prefix}.title`)
        }
        subTitle={
          recoverySuccess ? t(`${prefix}.successDesc`) : t(`${prefix}.desc`)
        }
      >
        {recoverySuccess ? (
          <PasswordRecoveryMailSuccess setIsOpen={setIsOpen} />
        ) : (
          <PasswordRecoveryMailForm
            setIsOpen={setIsOpen}
            setRecoverySuccess={setRecoverySuccess}
          />
        )}
      </AccountContainer>
    </Modal>
  );
}
