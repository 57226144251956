import { Redirect } from 'react-router-dom';
import { Loading } from '@octano/global-ui';
import useUserState from '../hooks/useUserState';
import { UserData } from '../types/auth';
interface CalculateRedirectFunctParams {
  userData?: UserData;
  isLogged?: boolean;
}

type calculateRedirectFunct = (
  params: CalculateRedirectFunctParams,
) => string | undefined;

type HOCReturnType = (props: any) => JSX.Element;

export const withLoggedInCheckRedirection = ({
  component: Component,
  calculateRedirectTo,
}: {
  component: (props: any) => JSX.Element;
  calculateRedirectTo: calculateRedirectFunct;
}): HOCReturnType => {
  return function WithLoggedInCheckRedirection(props: any) {
    const { isLogged, userData, hasCheckedToken } = useUserState();

    const redirectPath =
      calculateRedirectTo &&
      calculateRedirectTo({
        userData,
        isLogged,
      });

    if (!hasCheckedToken) {
      return <Loading textBody="" insideCard />;
    }

    return redirectPath ? (
      <Redirect to={redirectPath} />
    ) : (
      <Component {...props} />
    );
  };
};
