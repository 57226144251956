import {
  Account,
  LoginFields,
  LoginResponse,
  PasswordRecoveryFields,
  updateAccountType,
} from '../../types/auth';
import { AUTH } from '../endpoints';
import request from '../request';

/**
 * Busca la existencia de un RUT activo para crear cuenta de usuario
 * en caso de RUT activo existente el usuario podrá crear la cuenta
 */
export const getRutVerification = (rut: string, recaptchaToken: string) => {
  return request<Account>(AUTH.RUT_VERIFICATION, {
    params: { rut, recaptchaToken },
  });
};

/**
 * actualiza los datos de usuario de una cuenta existente
 */
export const updateUserAccount = (data: updateAccountType) => {
  const url = AUTH.UPDATE_ACCOUNT;
  return request<Account>(url, {
    method: 'PUT',
    data,
  });
};

/**
 * Envia correo de recuperación de contraseña
 */
export const sendPasswordRecoveryEmail = (rut: string) => {
  const url = AUTH.SEND_PASSWORD_RECOVERY_MAIL;
  return request<any>(url, {
    method: 'POST',
    data: { rut },
  });
};

/**
 * cambia la contraseña de usuario usando el token recibido en el
 * correo de recuperación de contraseña
 */
export const passwordRecovery = (data: PasswordRecoveryFields) => {
  const url = AUTH.PASSWORD_CHANGE;
  return request<any>(url, {
    method: 'PUT',
    data,
  });
};

/**
 * Inicio de sesión
 */
export const accountLogin = (data: LoginFields) => {
  const url = AUTH.LOGIN;
  return request<LoginResponse>(url, {
    method: 'POST',
    data,
  });
};

/**
 * Valida el token de recuperación de contraseña
 */
export const validateRecoveryToken = (email: string, token: string) => {
  return request<Account>(AUTH.VALIDATE_RECOERY_TOKEN, {
    params: { email, token },
  });
};
