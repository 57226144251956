import { ColumnTable, Button, CheckInput, Loading } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { User } from '../../../../../types/users';

interface Props {
  onUserStatusChange?: (rut: User['rut'], shouldActivate: boolean) => void;
  onChangeAdministrator: (rut: User['rut'], isAdministrator: boolean) => void;
  processingList?: string[];
  processingAdministrator?: number;
}

export default function useUserTableColumns({
  processingList = [],
  processingAdministrator,
  onUserStatusChange = () => null,
  onChangeAdministrator = () => null,
}: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const prefix = 'views.users.table';

  const columns: ColumnTable<User>[] = useMemo(() => {
    return [
      {
        columnName: 'rut',
        thClassName: 'text-center',
        tdClassName: 'text-center fw-700',
        headerText: t(`common.terms.rut`),
      },
      {
        columnName: 'name',
        thClassName: 'text-center',
        tdClassName: 'text-center text-capitalize',
        headerText: t(`common.terms.firstNames`),
        cellFormat: ({ row }) => row.name.toLowerCase(),
      },
      {
        columnName: 'phone',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.phone`),
        cellFormat: ({ row }) => row.phone,
      },
      {
        columnName: 'email',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.email`),
        cellFormat: ({ row }) => row.email || row.remEmail,
      },
      {
        columnName: 'isActive',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.actions.enable`),
        cellFormat: ({ row }) => {
          const isLoading = !!processingList.find((elem) => elem === row.rut);
          const isEnabled = !!row.userId;

          return (
            <Button
              type="button"
              text={
                isEnabled
                  ? t(`common.actions.disable`)
                  : t(`common.actions.enable`)
              }
              outlined={isEnabled}
              color={isEnabled ? 'danger' : 'primary'}
              size="sm"
              fullwidth
              loading={isLoading}
              onClick={() => {
                onUserStatusChange(row.rut, isEnabled);
              }}
            />
          );
        },
      },
      {
        columnName: 'isAdministrator',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.administrator`),
        cellFormat: ({ row }) => {
          return processingAdministrator === row.userId ? (
            <Loading textBody="" className="small-loading" />
          ) : (
            <CheckInput
              label=""
              name={`${row.rut}-administrator`}
              disabled={!row.userId}
              value={row.isAdministrator}
              onChange={() => {
                if (row.userId) {
                  onChangeAdministrator(row.rut, !row.isAdministrator);
                }
              }}
            />
          );
        },
      },
      {
        columnName: 'isAssigned',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.actions.assign`),
        cellFormat: ({ row }) => {
          return (
            <Button
              type="button"
              text={t(`${prefix}.assignProject`)}
              size="sm"
              fullwidth
              outlined
              disabled={!row.userId}
              onClick={() => {
                if (row.userId) {
                  history.push(`/maintainers/users/${row.userId}/projects`);
                }
              }}
            />
          );
        },
      },
    ];
  }, [
    t,
    processingList,
    onUserStatusChange,
    processingAdministrator,
    onChangeAdministrator,
    history,
  ]);

  return columns;
}
