import { ColumnTable, Icon } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AnnualSummary } from '../../../../types/financial-flow';
import { formatCurrency } from '../../../../utils/currency';
import { getMonthName } from '../../../../utils/date';

interface Props {
  viewDetailOnClick: (month: string) => void;
}

export default function useTableColumns({
  viewDetailOnClick = () => null,
}: Props) {
  const { t } = useTranslation();

  const columns: ColumnTable<AnnualSummary>[] = useMemo(() => {
    const tariffFormatter = formatCurrency({ symbol: false });
    return [
      {
        columnName: 'month',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.month`),
        cellFormat: ({ row }) => {
          return getMonthName(Number(row.month));
        },
      },
      {
        columnName: 'income',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.income`),
        cellFormat: ({ row }) => {
          return tariffFormatter.format(Number(row.income));
        },
      },
      {
        columnName: 'outflow',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.outflow`),
        cellFormat: ({ row }) => {
          return tariffFormatter.format(Number(row.outflow));
        },
      },
      {
        columnName: 'amount',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.amount`),
        cellFormat: ({ row }) => {
          return tariffFormatter.format(Number(row.amount));
        },
      },
      {
        columnName: 'action',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.action`),
        cellFormat: ({ row }) => {
          return (
            <span
              className="cursor-pointer"
              onClick={() => viewDetailOnClick(row.month)}
            >
              <Icon name="eye" />
            </span>
          );
        },
      },
    ];
  }, [t, viewDetailOnClick]);

  return columns;
}
