import { addToast, Button, TextInput } from '@octano/global-ui';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { updateUserAccount } from '../../../api/requests/auth';
import { PathsLayouts } from '../../../config/routes';
import { useValidations } from '../../../hooks/useValidations';
import { Account, updateAccountFormFields } from '../../../types/auth';
import AccountContainer from '../AccountContainer';

export default function UpdateAccountForm() {
  const { t } = useTranslation();
  const prefix = `views.account.create`;
  const history = useHistory();
  const location = useLocation<Account>();
  const { id: userId } = useParams<{ id: string }>();

  const rut = useMemo(() => location.state?.rut || undefined, [location]);
  const email = useMemo(() => location.state?.email || undefined, [location]);

  const {
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm<updateAccountFormFields>({
    mode: 'onChange',
    defaultValues: {
      phone: undefined,
      rut,
      password: undefined,
      passwordConfirm: undefined,
    },
  });

  const password = watch('password');

  const {
    validateTextNotEmpty,
    validatePhone,
    validatePassword,
    validateRut,
    validateRepeatPassword,
    msgValidations,
  } = useValidations();

  const handleSubmitCreation = async (values: updateAccountFormFields) => {
    const { error } = await updateUserAccount({
      id: parseInt(userId),
      email,
      phone: values.phone,
      password: values.password,
      passwordConfirm: values.passwordConfirm,
    });

    if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`common.terms.unexpectedError`),
      });
    } else {
      history.push(`${PathsLayouts.auth}/account/activation/success`);
    }
  };

  if (!rut || !email) {
    history.push(`${PathsLayouts.auth}/account/validation`);
  }

  return (
    <AccountContainer
      title={t(`${prefix}.title`)}
      subTitle={t(`${prefix}.desc`)}
      hasLinkUndercard
      wrapped
    >
      <Row>
        <Col xs={12} className="pt-2">
          <form onSubmit={handleSubmit(handleSubmitCreation)}>
            <Row>
              <Col xs={12}>
                <TextInput
                  name="phone"
                  control={control}
                  type="text"
                  label={t('common.terms.phone')}
                  rules={{
                    validate: {
                      empty: validateTextNotEmpty,
                      phone: validatePhone,
                    },
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <TextInput
                  name="rut"
                  control={control}
                  type="text"
                  label={t('common.terms.rut')}
                  rules={{
                    validate: {
                      empty: validateTextNotEmpty,
                      rut: validateRut,
                    },
                  }}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <TextInput
                  name="password"
                  control={control}
                  type="password"
                  label={t('common.terms.password')}
                  tooltip={msgValidations.invalidPassword}
                  rules={{
                    validate: {
                      notEmpty: validateTextNotEmpty,
                      password: validatePassword,
                    },
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <TextInput
                  name="passwordConfirm"
                  control={control}
                  type="password"
                  label={t('common.terms.passwordConfirm')}
                  rules={{
                    validate: {
                      notEmpty: validateTextNotEmpty,
                      password: validateRepeatPassword(password),
                    },
                  }}
                />
              </Col>
            </Row>
            <div className="py-4">
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
                text={t(`common.actions.next`)}
                fullwidth
              />
            </div>
          </form>
        </Col>
      </Row>
    </AccountContainer>
  );
}
