import { useState } from 'react';

export const useLoading = (initialLoadingState?: boolean) => {
  const [loading, setLoading] = useState(initialLoadingState ?? true);
  const [errorLoading, setErrorLoading] = useState<
    'CONNECTION' | 'HTTP_ERROR' | string
  >();

  return { loading, setLoading, errorLoading, setErrorLoading };
};
