import { Button, DateInput } from '@octano/global-ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import useQuery, { Parameters } from '../../../../hooks/useQuery';
import { useForm } from 'react-hook-form';
import { prefix } from '..';
import { formatDate } from '../../../../utils/date';

export default function SearchFilters() {
  const { t } = useTranslation();

  const { getParam, setParams } = useQuery();
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      searchFromDate: getParam('searchFromDate') || '',
      searchToDate: getParam('searchToDate') || '',
    },
  });

  const onSubmit = useCallback(
    (values) => {
      const valuesNotEmpty: Parameters = {};

      if (values.searchFromDate) {
        valuesNotEmpty.searchFromDate = formatDate(
          values.searchFromDate,
          'YYYY-MM-DD',
        );
      }
      if (values.searchToDate) {
        valuesNotEmpty.searchToDate = formatDate(
          values.searchToDate,
          'YYYY-MM-DD',
        );
      }

      setParams(valuesNotEmpty);
    },
    [setParams],
  );

  const onClear = useCallback(() => {
    setParams({});
    setValue('searchFromDate', '');
    setValue('searchToDate', '');
  }, [setParams, setValue]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="d-flex align-items-center justify-content-start pb-2">
        <Col xs={12} md={4} lg={3} xl={2}>
          <DateInput
            name="searchFromDate"
            label={t(`${prefix}.dateFrom`)}
            control={control}
          />
        </Col>
        <Col xs={12} md={4} lg={3} xl={2}>
          <DateInput
            name="searchToDate"
            label={t(`${prefix}.dateTo`)}
            control={control}
          />
        </Col>
        <Col xs={12} md={2} lg={2} xl={1} className="pt-2">
          <Button
            type="submit"
            text={t(`common.actions.search`)}
            size="md"
            fullwidth
          />
        </Col>
        <Col xs={12} md={2} lg={2} xl={1} className="pt-2">
          <Button
            text={t(`common.actions.clear`)}
            size="md"
            fullwidth
            outlined
            onClick={onClear}
          />
        </Col>
      </Row>
    </Form>
  );
}
