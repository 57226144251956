import { Button, Modal, TextInput } from '@octano/global-ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import { useValidations } from '../../../../../hooks/useValidations';
import { format } from 'rut.js';

type UserAddFields = {
  rut: string;
  name: string;
  lastName: string;
  email: string;
  phone: string;
};

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  onSubmit: (params: any) => void;
  onCancel: (resetForm: any) => void;
}

export default function UserAddModal({
  isOpen,
  isLoading,
  onSubmit = () => null,
  onCancel = () => null,
}: Props) {
  const { t } = useTranslation();
  const prefix = `views.users.add`;

  const { validateTextNotEmpty, validatePhone, validateEmail, validateRut } =
    useValidations();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm<UserAddFields>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <Modal isOpen={isOpen} toggle={() => null} size="lg">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h1 className="text-dark fs-22 mb-0 text-center">
          {t(`${prefix}.title`)}
        </h1>

        <Row className={'pt-5'}>
          <Col md={12}>
            <Row>
              <Col md={6}>
                <TextInput
                  label={t(`common.terms.rut`)}
                  name="rut"
                  formatter={(rut) => format(rut, { dots: false })}
                  control={control}
                  rules={{
                    validate: {
                      notEmpty: validateTextNotEmpty,
                      email: validateRut,
                    },
                  }}
                />
              </Col>
              <Col md={6}>
                <TextInput
                  label={t(`common.terms.firstNames`)}
                  name="name"
                  control={control}
                  rules={{
                    validate: {
                      notEmpty: validateTextNotEmpty,
                    },
                  }}
                />
              </Col>
              <Col md={6}>
                <TextInput
                  label={t(`common.terms.lastNames`)}
                  name="lastName"
                  control={control}
                  rules={{
                    validate: {
                      notEmpty: validateTextNotEmpty,
                    },
                  }}
                />
              </Col>
              <Col md={6}>
                <TextInput
                  label={t(`common.terms.email`)}
                  name="email"
                  control={control}
                  rules={{
                    validate: {
                      notEmpty: validateTextNotEmpty,
                      email: validateEmail,
                    },
                  }}
                />
              </Col>
              <Col md={6}>
                <TextInput
                  label={t(`common.terms.phone`)}
                  name="phone"
                  control={control}
                  placeholder="+56912345678"
                  rules={{
                    validate: {
                      phone: validatePhone,
                    },
                  }}
                />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={12} md={4} className="pt-4">
                <Button
                  text={t('common.actions.cancel')}
                  outlined
                  size="md"
                  fullwidth
                  onClick={() => onCancel(reset)}
                />
              </Col>
              <Col xs={12} md={4} className="pt-4">
                <Button
                  type="submit"
                  size="md"
                  fullwidth
                  loading={isLoading}
                  text={t(`${prefix}.buttonSave`)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
