import clsx from 'clsx';
import { Row, Col, Container } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon, UserMenu, useSidenav, useMobile } from '@octano/global-ui';
import useUserState from '../../hooks/useUserState';
import { PathsLayouts } from '../../config/routes';

interface Props {
  title: string;
}

const TopBar = ({ title }: Props) => {
  const { t } = useTranslation();
  const prefix = `modules.titles`;
  const isMobile = useMobile();
  const history = useHistory();
  const { toggle: toggleSidenav } = useSidenav();
  const { userData, clearUser } = useUserState();

  const logOut = () => {
    clearUser();
    history.push(PathsLayouts.auth);
  };

  return (
    <div className="g-tabs-header">
      <div className={clsx('bar', isMobile && 'barMobile')}>
        <Container fluid>
          <Row className="align-items-center justify-content-between">
            {isMobile && (
              <Col xs="auto">
                <span onClick={() => toggleSidenav()}>
                  <Icon name="menu" size="30px" className="menuIcon" />
                </span>
              </Col>
            )}
            {!isMobile && (
              <Col>
                <span className="title">{t(`${prefix}.${title}`)}</span>
              </Col>
            )}
            <Col xs="auto">
              <UserMenu
                fullName={userData?.fullName || ''}
                email={userData?.email || ''}
                menuItems={[
                  {
                    icon: 'log_out',
                    text: t(`common.actions.logOut`),
                    onClick: logOut,
                  },
                ]}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {isMobile && (
        <Container fluid>
          <h1 className="title">{t(`${prefix}.${title}`)}</h1>
        </Container>
      )}
    </div>
  );
};

export default TopBar;
