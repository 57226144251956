import { Button, Modal, Icon } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

interface Props {
  isOpen: boolean;
  messageConfirmation: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export default function ActivationConfirmationModal({
  isOpen,
  messageConfirmation,
  onConfirm = () => null,
  onCancel = () => null,
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} toggle={() => null}>
      <div className="d-flex flex-column align-items-center">
        <Icon name="warning" size={50} />
        <h1 className="text-dark fs-22 mb-0 mt-4 text-center">
          {messageConfirmation}
        </h1>
        <Row>
          <Col xs={6} md={6} className="pt-4">
            <Button
              text={t('common.actions.cancel')}
              outlined
              size="md"
              onClick={() => onCancel()}
            />
          </Col>
          <Col xs={6} md={6} className="pt-4">
            <Button
              size="md"
              text={t('common.actions.confirm')}
              onClick={() => onConfirm()}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
}
