import { PaginationType } from '@octano/global-ui';
import { useState } from 'react';

const DEFAULT_FIRST_PAGE = 1;
const DEFAULT_ITEMS_PER_PAGE = 10;

export const usePagination = (
  itemsPerPage: number = DEFAULT_ITEMS_PER_PAGE,
  firstPage: number = DEFAULT_FIRST_PAGE,
) => {
  const [pagination, setPagination] = useState<PaginationType>();
  const [page, setCurrentPage] = useState<number>(firstPage);

  return {
    page,
    setCurrentPage,
    pagination,
    setPagination,
    itemsPerPage,
  };
};
