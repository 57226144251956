import { PermissionName } from '../types/auth';
import Login from '../views/account/login';
import AccountValidation from '../views/account/create/AccountValidationForm';
import UpdateAccountForm from '../views/account/create/UpdateAccountForm';
import UpdateAccountSuccess from '../views/account/create/UpdateAccountSuccess';
import PasswordRecoveryForm from '../views/account/recovery/PasswordRecoveryForm';
import PasswordRecoveryFinish from '../views/account/recovery/PasswordRecoveryFinish';
import AuthLayout from '../layouts/AuthLayout';
import Home from '../views/home';
import UsersTable from '../views/maintainers/users/table';
import SidenavLayout from '../layouts/SidenavLayout';
import ProjectsTable from '../views/maintainers/projects/table';
import AssignProjectsTable from '../views/financialFlow/assignedProjects';
import AnnualSummaryTable from '../views/financialFlow/annualSummary';
import MonthlyDetailTable from '../views/financialFlow/monthlySummary';
import CollectionManagementAssignProjectsTable from '../views/collectionManagement/assignedProjects';
import NotesReceivableAssignProjectsTable from '../views/notesReceivable/assignedProjects';
import MonthlyResultAssignProjectsTable from '../views/monthlyResult/assignedProjects';
import PaymentStatusAssignProjectsTable from '../views/paymentStatus/assignedProjects';
import CollectionManagementPaymentsDetails from '../views/collectionManagement/paymentsSummary';
import DoctypeSummaryTable from '../views/notesReceivable/docTypeSummary';
import DoctypeDetailSummaryTable from '../views/notesReceivable/doctypeDetailSummary';
import PaymentStatusSummaryList from '../views/paymentStatus/paymentsSummary';

export enum PathsLayouts {
  auth = '/auth',
  maintainers = '/maintainers',
  financialFlow = '/financial-flow',
  collectionManagement = '/collection-management',
  notesReceivable = '/notes-receivable',
  monthlyResult = '/monthly-result',
  paymentStatus = '/payment-status',
  // home = '/',
}

export type ViewRouteType = {
  path: string;
  component: () => JSX.Element;
};

export type RoutesByLayout = {
  // this keys are the paths of every layout
  [key in PathsLayouts]: {
    layoutComponent: (props: any) => JSX.Element;
    defaultPath: string;
    views: ViewRouteType[];
    requiredPermissions?: PermissionName[];
    allPermissionsRequired?: boolean;
  };
};

/**
 * Contiene todos los layouts con sus vistas
 */
const routes: RoutesByLayout = {
  [PathsLayouts.auth]: {
    layoutComponent: AuthLayout,
    defaultPath: '/login',
    views: [
      {
        path: '/login',
        component: Login,
      },
      {
        path: '/account/validation',
        component: AccountValidation,
      },
      {
        path: '/account/update/:id',
        component: UpdateAccountForm,
      },
      {
        path: '/account/activation/success',
        component: UpdateAccountSuccess,
      },
      {
        path: '/reset-password',
        component: PasswordRecoveryForm,
      },
      {
        path: '/account/recovery/finish',
        component: PasswordRecoveryFinish,
      },
    ],
  },
  [PathsLayouts.maintainers]: {
    layoutComponent: SidenavLayout,
    defaultPath: '/users',
    requiredPermissions: [PermissionName.userShow],
    views: [
      {
        path: '/users',
        component: UsersTable,
      },
      {
        path: '/users/:id/projects',
        component: ProjectsTable,
      },
    ],
  },
  [PathsLayouts.financialFlow]: {
    layoutComponent: SidenavLayout,
    defaultPath: '/assigned-projects',
    requiredPermissions: [PermissionName.financialShow],
    views: [
      {
        path: '/assigned-projects',
        component: AssignProjectsTable,
      },
      {
        path: '/:projectCode/annual-summary',
        component: AnnualSummaryTable,
      },
      {
        path: '/:projectCode/annual-summary/monthly-detail',
        component: MonthlyDetailTable,
      },
    ],
  },
  [PathsLayouts.collectionManagement]: {
    layoutComponent: SidenavLayout,
    defaultPath: '/assigned-projects',
    requiredPermissions: [PermissionName.collectionManagementShow],
    views: [
      {
        path: '/assigned-projects',
        component: CollectionManagementAssignProjectsTable,
      },
      {
        path: '/:projectCode/summary-list',
        component: CollectionManagementPaymentsDetails,
      },
    ],
  },
  [PathsLayouts.notesReceivable]: {
    layoutComponent: SidenavLayout,
    defaultPath: '/assigned-projects',
    requiredPermissions: [PermissionName.notesReceivableShow],
    views: [
      {
        path: '/assigned-projects',
        component: NotesReceivableAssignProjectsTable,
      },
      {
        path: '/:projectCode/doctype-summary',
        component: DoctypeSummaryTable,
      },
      {
        path: '/:projectCode/doctype-summary/detail',
        component: DoctypeDetailSummaryTable,
      },
    ],
  },
  [PathsLayouts.monthlyResult]: {
    layoutComponent: SidenavLayout,
    defaultPath: '/assigned-projects',
    requiredPermissions: [PermissionName.monthlyResultShow],
    views: [
      {
        path: '/assigned-projects',
        component: MonthlyResultAssignProjectsTable,
      },
    ],
  },
  [PathsLayouts.paymentStatus]: {
    layoutComponent: SidenavLayout,
    defaultPath: '/assigned-projects',
    requiredPermissions: [PermissionName.paymentStatusShow],
    views: [
      {
        path: '/assigned-projects',
        component: PaymentStatusAssignProjectsTable,
      },
      {
        path: '/:projectCode/summary-list',
        component: PaymentStatusSummaryList,
      },
    ],
  },
  // [PathsLayouts.home]: {
  //   layoutComponent: AuthLayout,
  //   defaultPath: '/',
  //   views: [
  //     {
  //       path: '/',
  //       component: Home,
  //     },
  //   ],
  // },
};

export default routes;
