import { ColumnTable, Button } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Project } from '../../../../../types/projects';

interface Props {
  unassignOnClick: (projectCode: Project['projectCode']) => void;
  unassignButtonLoading?: string;
}

export default function useProjectAssignedTableColumns({
  unassignOnClick,
  unassignButtonLoading,
}: Props) {
  const { t } = useTranslation();
  const prefix = `views.projects.tableProjectsAssigned`;

  const columns: ColumnTable<Project>[] = useMemo(() => {
    return [
      {
        columnName: 'code',
        thClassName: 'text-center',
        tdClassName: 'text-center fw-700',
        headerText: t(`common.terms.costCenter`),
      },
      {
        columnName: 'school',
        headerText: t(`common.terms.school`),
      },
      {
        columnName: 'department',
        headerText: t(`common.terms.department`),
        cellFormat: ({ row }) => row.department || 'N/A',
      },
      {
        columnName: 'name',
        headerText: t(`common.terms.projectName`),
      },
      {
        columnName: 'unassigned',
        thClassName: 'justify-content-end',
        tdClassName: 'justify-content-end',
        headerText: '',
        cellFormat: ({ row }) => {
          return (
            <Button
              color="danger"
              outlined
              size="sm"
              fullwidth
              text={t(`${prefix}.removeAssignment`)}
              loading={unassignButtonLoading === row.projectCode}
              onClick={() => unassignOnClick(row.projectCode)}
            />
          );
        },
      },
    ];
  }, [prefix, t, unassignOnClick, unassignButtonLoading]);

  return columns;
}
