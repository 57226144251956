import { addToast, Button, Table } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import {
  getDoctypeDetailFilter,
  getDoctypeDetailSummaryByDoctype,
  getDoctypeDetailSummaryToExcel,
  getDoctypeDetailSummaryToPdf,
  getFinancialSummaryByDoctype,
  ParametersDoctypeDetailSummary,
} from '../../../api/requests/notes-receivable';
import { ParametersProjectsAssignedPagination } from '../../../api/requests/notes-receivable';
import { getProject } from '../../../api/requests/projects';
import TableEmptyContent from '../../../components/TableEmptyContent';
import { PathsLayouts } from '../../../config/routes';
import { useLoading } from '../../../hooks/useLoading';
import { usePagination } from '../../../hooks/usePagination';
import useQuery from '../../../hooks/useQuery';
import {
  DoctypeDetailSummary,
  DoctypeSummary,
} from '../../../types/notes-receivable';
import { Project } from '../../../types/projects';
import { downloadFromBlob } from '../../../utils/blob';
import { resetPagination } from '../../../utils/resetPagination';
import Exportable from './parts/Exportable';
import ExportableLoading from './parts/ExportableLoading';
import ProjectInfo from './parts/ProjectInfo';
import SearchFilters from './parts/SearchFilters';
import Summary from './parts/Summary';
import useTableColumns from './parts/useTableColumns';

export interface FiltersDefault {
  documentType: string;
}
export const prefix = `views.notesReceivable.tableDoctypeDetailSummaryList`;

export default function DoctypeDetailSummaryTable() {
  const { t } = useTranslation();
  const history = useHistory();
  const { getParam, currentParamsAsObject } = useQuery();
  const { loading: loadingExportFile, setLoading: setLoadingExportFile } =
    useLoading(false);
  const { loading: loadingSummary, setLoading: setLoadingSummary } =
    useLoading();
  const { loading, setLoading } = useLoading();
  const { page, pagination, setCurrentPage, setPagination, itemsPerPage } =
    usePagination();

  const [summary, setSummary] = useState<DoctypeSummary[]>([]);
  const [detail, setDetail] = useState<DoctypeDetailSummary[]>([]);
  const [projectData, setProjectData] = useState<Partial<Project>[]>([]);
  const [filtersDefaultValues, setFiltersDefaultValues] =
    useState<FiltersDefault>({ documentType: '' });
  const [doctypesFilters, setDoctypesFilters] = useState<any[]>([]);

  const { projectCode } = useParams<{ projectCode: string }>();

  /** Datos por defecto para los filtros */
  const getDoctypesFilters = useCallback(async () => {
    const { data, error } = await getDoctypeDetailFilter(projectCode);

    if (error || !data) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`common.terms.unexpectedError`),
      });
    }

    setDoctypesFilters(data ? data : []);
  }, [projectCode, t]);

  useEffect(() => {
    getDoctypesFilters();
    setFiltersDefaultValues({ documentType: getParam('searchDoctype') || '' });
    // eslint-disable-next-line
  }, [getDoctypesFilters]);

  /** Get project data */
  const getProjectData = useCallback(async () => {
    const { data, error } = await getProject(projectCode);

    if (error || !data) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`common.terms.unexpectedError`),
      });
    }

    setProjectData(data ? [data] : []);
  }, [t, projectCode]);

  useEffect(() => {
    getProjectData();
  }, [getProjectData]);

  /** get financial summary by doctype  */
  const getDoctypeSummaryByDoctype = useCallback(async () => {
    setLoadingSummary(true);
    let params: ParametersDoctypeDetailSummary = {};

    const currentDoctype =
      getParam('searchDoctype') || filtersDefaultValues.documentType;
    const currentSearchRut = getParam('searchRut');
    const currentSearchFromDate = getParam('searchFromDate');
    const currentSearchToDate = getParam('searchToDate');

    if (currentDoctype) params.searchDoctype = currentDoctype;
    if (currentSearchRut) params.searchRut = currentSearchRut;
    if (currentSearchFromDate) params.searchFromDate = currentSearchFromDate;
    if (currentSearchToDate) params.searchToDate = currentSearchToDate;
    const { data, error } = await getFinancialSummaryByDoctype(
      projectCode,
      params,
    );

    if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`common.terms.unexpectedError`),
      });
      return;
    }

    if (data) {
      setSummary(data ? data : []);
    }
    setLoadingSummary(false);
  }, [projectCode, filtersDefaultValues, setLoadingSummary, getParam, t]);

  useEffect(() => {
    getDoctypeSummaryByDoctype();
  }, [getDoctypeSummaryByDoctype]);

  /** get summary by doctype*/
  const getDoctypeSummary = useCallback(
    async (pageNumber?: number) => {
      setLoading(true);
      const currentPage = pageNumber || page;
      let params: ParametersProjectsAssignedPagination = {
        page: (currentPage - 1).toString(),
        itemsPerPage: itemsPerPage.toString(),
      };

      const currentDoctype =
        getParam('searchDoctype') || filtersDefaultValues.documentType;
      const currentSearchRut = getParam('searchRut');
      const currentSearchFromDate = getParam('searchFromDate');
      const currentSearchToDate = getParam('searchToDate');

      if (currentDoctype) params.searchDoctype = currentDoctype;
      if (currentSearchRut) params.searchRut = currentSearchRut;
      if (currentSearchFromDate) params.searchFromDate = currentSearchFromDate;
      if (currentSearchToDate) params.searchToDate = currentSearchToDate;

      const { data, error } = await getDoctypeDetailSummaryByDoctype(
        projectCode,
        params,
      );

      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`common.terms.unexpectedError`),
        });
        setLoading(false);
        return;
      }

      if (data) {
        setDetail(data.data);
        setPagination({
          totalItems: data.total,
          itemsPerPage: itemsPerPage,
          totalPages: data.totalPages,
          currentPage: currentPage,
          onChangePage: (pageNumber: number) => setCurrentPage(pageNumber),
        });
      }
      setLoading(false);
    },
    [
      t,
      setDetail,
      setLoading,
      setCurrentPage,
      setPagination,
      getParam,
      page,
      itemsPerPage,
      projectCode,
      filtersDefaultValues,
    ],
  );

  useEffect(() => {
    resetPagination(currentParamsAsObject, getDoctypeSummary);
  }, [getDoctypeSummary, currentParamsAsObject]);

  /** get table columns */
  const columns = useTableColumns();

  /** function to export excel or pdf document from list data */
  const xport = useCallback(
    async (values) => {
      if (!values || !values.exportable || !values.exportable.value) {
        return;
      }
      setLoadingExportFile(true);
      let params: ParametersDoctypeDetailSummary = {};

      const currentDoctype =
        getParam('searchDoctype') || filtersDefaultValues.documentType;
      const currentSearchRut = getParam('searchRut');
      const currentSearchFromDate = getParam('searchFromDate');
      const currentSearchToDate = getParam('searchToDate');

      if (currentDoctype) params.searchDoctype = currentDoctype;
      if (currentSearchRut) params.searchRut = currentSearchRut;
      if (currentSearchFromDate) params.searchFromDate = currentSearchFromDate;
      if (currentSearchToDate) params.searchToDate = currentSearchToDate;

      if (values.exportable.value === 'excel') {
        const { data, error } = await getDoctypeDetailSummaryToExcel(
          projectCode,
          params,
        );
        if (error) {
          setLoadingExportFile(false);
          addToast({
            icon: 'error',
            color: 'danger',
            text: t(`common.terms.unexpectedError`),
          });
          return;
        }
        if (data) {
          downloadFromBlob(
            data,
            `Reporte_Documentos_Por_Cobrar-${projectCode}-${Date.now()}.xlsx`,
          );
          addToast({
            icon: 'success',
            color: 'success',
            text: t(`${prefix}.fileDownloadedSuccessfully`),
          });
        }
      }
      if (values.exportable.value === 'pdf') {
        const { data, error } = await getDoctypeDetailSummaryToPdf(
          projectCode,
          params,
        );
        if (error) {
          setLoadingExportFile(false);
          addToast({
            icon: 'error',
            color: 'danger',
            text: t(`common.terms.unexpectedError`),
          });
          return;
        }
        if (data) {
          downloadFromBlob(
            data,
            `Reporte_Documentos_Por_Cobrar-${projectCode}-${Date.now()}`,
          );
          addToast({
            icon: 'success',
            color: 'success',
            text: t(`${prefix}.fileDownloadedSuccessfully`),
          });
        }
      }
      setLoadingExportFile(false);
    },
    [projectCode, filtersDefaultValues, setLoadingExportFile, getParam, t],
  );

  return (
    <>
      <ExportableLoading isOpen={loadingExportFile} />
      <Row className="d-flex align-items-center pb-2">
        <Col xs={12} md={6}>
          <Button
            icon="back"
            onClick={() =>
              history.push(
                `${PathsLayouts.notesReceivable}/${projectCode}/doctype-summary`,
              )
            }
            outlined
            rounded
            size="sm"
            text={t(`common.actions.backToThePreviousStep`)}
          />
        </Col>
      </Row>

      <Row className="mt-5 mb-4">
        <Col sm={12} md={12}>
          <ProjectInfo data={projectData} />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col sm={12} md={12}>
          <SearchFilters
            defaultValues={filtersDefaultValues}
            filters={{
              documentTypes: doctypesFilters,
            }}
          />
        </Col>
      </Row>

      <Row className="pb-4 align-items-end">
        <Col sm={12} md={6}>
          <Summary data={summary} loading={loadingSummary} />
        </Col>

        <Col sm={12} md={6}>
          <Exportable onSubmit={xport} />
        </Col>
      </Row>

      <Table
        borderless
        striped={false}
        columns={columns}
        data={detail}
        pagination={pagination}
        isLoadingResults={loading}
        noResultsText={
          <TableEmptyContent
            title={t(`${prefix}.emptyTitle`)}
            subtitle={t(`${prefix}.emptyDesc`)}
          />
        }
      />
    </>
  );
}
