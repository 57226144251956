import { Button, SearchBox } from '@octano/global-ui';
import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';
import useQuery from '../../../../../hooks/useQuery';

export default function SearchFilters() {
  const { t } = useTranslation();
  const prefix = `views.users.table`;

  const { getParam, setParams } = useQuery();
  const [text, setText] = useState<string | undefined>(getParam('search'));

  const applyFilters = useCallback(
    (value = '') => {
      setText(value);
      setParams({ search: value });
    },
    [setParams],
  );

  return (
    <>
      <Col xs={12} md={3}>
        <SearchBox
          name="search"
          label={t(`${prefix}.searchLabel`)}
          placeholder={t(`${prefix}.searchPlaceholder`)}
          value={text}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setText(e.currentTarget.value);
          }}
        />
      </Col>
      <Col xs={12} md={2} className="pt-2">
        <Button
          type="submit"
          text={t(`common.actions.search`)}
          size="md"
          fullwidth
          onClick={() => applyFilters(text)}
        />
      </Col>
      <Col xs={12} md={2} className="pt-2">
        <Button
          type="submit"
          text={t(`common.actions.clear`)}
          size="md"
          fullwidth
          outlined
          onClick={() => applyFilters()}
        />
      </Col>
    </>
  );
}
