import dayjs from 'dayjs';

const MonthsName = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

/**
 * Permite obtener el nombre del mes a través de su número relacionado
 */
export function getMonthName(month: number) {
  return MonthsName[month - 1] || '';
}

/**
 * Permite listar los meses
 */
export function listMonthsName() {
  return MonthsName.map((month, key) => {
    return { key: key + 1, value: month };
  });
}

/**
 * Permite formatear una fecha
 */
export function formatDate(
  date: string | Date,
  format: 'DD-MM-YYYY' | 'DD/MM/YYYY' | 'YYYY-MM-DD' = 'DD-MM-YYYY',
): string {
  return dayjs(date).isValid() ? dayjs.utc(date).format(format) : '';
}
