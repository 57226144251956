import {
  Icon,
  IconNameType,
  Logo,
  SingleCardContainer,
} from '@octano/global-ui';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { PathsLayouts } from '../../config/routes';

interface Props {
  icon?: IconNameType;
  title?: string;
  subTitle?: string;
  children: JSX.Element;
  hasLinkUndercard?: boolean;
  wrapped?: boolean;
}

export default function AccountContainer({
  icon,
  title,
  subTitle,
  children,
  hasLinkUndercard,
  wrapped,
}: Props) {
  const { t } = useTranslation();
  const prefix = `views.account.create`;
  const content = useMemo(() => {
    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className="text-center">
            <Logo
              alt="logo"
              style={{ maxHeight: 80 }}
              src="/tenant/logo.svg"
              fallbackSrc="/tenant/logo.png"
            />
          </Col>
          {icon && (
            <Col xs={12} className="mt-4 d-flex justify-content-center">
              <Icon name={icon} key={icon} size="45px" className="mt-5" />
            </Col>
          )}
          {title && (
            <Col xs={12} className="mt-4 d-flex justify-content-center">
              <p className="text-center text-dark fs-20">{title}</p>
            </Col>
          )}
          {subTitle && (
            <Col xs={12} className="mb-4 d-flex justify-content-center">
              <p className="text-center mb-0 fs-18">{subTitle}</p>
            </Col>
          )}
        </Row>
        {children}
      </React.Fragment>
    );
  }, [icon, title, subTitle, children]);

  return (
    <React.Fragment>
      {wrapped ? (
        <SingleCardContainer
          linkUndercard={
            !hasLinkUndercard ? undefined : (
              <Link
                className="mb-0 ml-1 text-center anchor-like"
                to={`${PathsLayouts.auth}`}
              >
                {t(`${prefix}.goBack`)}{' '}
                <Icon name="chevron_right" key="chevron_right" size="8px" />
              </Link>
            )
          }
        >
          {content}
        </SingleCardContainer>
      ) : (
        <div>{content}</div>
      )}
    </React.Fragment>
  );
}
