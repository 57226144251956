import { Redirect, useRouteMatch } from 'react-router-dom';
import { Loading } from '@octano/global-ui';
import useUserState from '../hooks/useUserState';
import routes, { PathsLayouts, RoutesByLayout } from '../config/routes';
import { UserData } from '../types/auth';
import { useMemo } from 'react';

type PathLayoutEntry = [PathsLayouts, RoutesByLayout[PathsLayouts]];

interface CalculateRedirectFunctParams {
  userData?: UserData;
  isAuthorized?: boolean;
  authorizedModulesEntries?: PathLayoutEntry[];
  hasCheckedToken: boolean;
}

type calculateRedirectFunct = (
  params: CalculateRedirectFunctParams,
) => string | undefined;

type HOCReturnType = (props: any) => JSX.Element;

export const withPermissionsCheckRedirection = ({
  component: Component,
  calculateRedirectTo,
}: {
  component: (props: any) => JSX.Element;
  calculateRedirectTo: calculateRedirectFunct;
}): HOCReturnType => {
  return function WithPermissionsCheckRedirection(props: any) {
    const { userData, hasCheckedToken, isAuthorizedTo } = useUserState();

    const routeMatch = useRouteMatch({ exact: true });
    const moduleMatch = useRouteMatch({
      path: Object.values(PathsLayouts),
    });

    const authorizedModulesEntries = useMemo(() => {
      if (!hasCheckedToken) {
        return [];
      }

      return Object.entries(routes).filter(([_, value]) => {
        if (!value.requiredPermissions?.length) return true;

        return isAuthorizedTo(
          value?.requiredPermissions || [],
          value.allPermissionsRequired,
        );
      });
    }, [hasCheckedToken, isAuthorizedTo]) as PathLayoutEntry[];

    const isAuthorized = useMemo(() => {
      return authorizedModulesEntries.some(([key, value]) =>
        value.views.find((view) => {
          return (
            key + view.path === routeMatch?.path || key === moduleMatch?.path
          );
        }),
      );
    }, [authorizedModulesEntries, routeMatch?.path, moduleMatch?.path]);

    const redirectPath =
      calculateRedirectTo &&
      calculateRedirectTo({
        userData,
        isAuthorized,
        authorizedModulesEntries,
        hasCheckedToken,
      });

    if (!hasCheckedToken) {
      return <Loading textBody="" insideCard />;
    }

    return redirectPath ? (
      <Redirect to={redirectPath} />
    ) : (
      <Component {...props} />
    );
  };
};
