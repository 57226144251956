import { Button, OutlinedSelect } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { ListsDictionary } from '..';
import { getMonthName } from '../../../../utils/date';

interface Props {
  filters: ListsDictionary;
  defaultValues: { year: number; month: number };
  onSubmit: (params: SubmitHandler<FieldValues>) => void;
}

export default function SearchFilters({
  filters,
  defaultValues,
  onSubmit,
}: Props) {
  const prefix = `views.financialFlow.tableMonthlyDetail`;
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      year: {
        label: defaultValues.year,
        value: defaultValues.year,
      },
      month: {
        label: getMonthName(defaultValues.month),
        value: defaultValues.month,
      },
    },
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="d-flex align-items-center justify-content-end pb-2">
        <Col xs={12} md={4}>
          <OutlinedSelect
            name="year"
            label={t(`${prefix}.yearFilterLabel`)}
            isClearable={false}
            control={control}
            options={filters.years}
          />
        </Col>
        <Col xs={12} md={4}>
          <OutlinedSelect
            name="month"
            label={t(`${prefix}.monthFilterLabel`)}
            isClearable={false}
            control={control}
            options={filters.months}
          />
        </Col>

        <Col xs={12} md={3} className="pt-2">
          <Button
            type="submit"
            text={t(`common.actions.search`)}
            size="md"
            fullwidth
          />
        </Col>
      </Row>
    </Form>
  );
}
