import { ToastProvider } from '@octano/global-ui';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import AppVersion from './components/AppVersion/AppVersion';
import routes from './config/routes';
import UserStateProvider from './providers/UserStateProvider';

function App() {
  return (
    <ToastProvider>
      <UserStateProvider>
        <AppVersion />
        <Router>
          <Switch>
            {Object.entries(routes)?.map(([key, value]) => {
              const Layout = value.layoutComponent;
              return (
                <Route
                  key={key}
                  path={key}
                  render={(props) => <Layout {...props} />}
                />
              );
            })}
            <Redirect from="*" to="/auth" />
          </Switch>
        </Router>
      </UserStateProvider>
    </ToastProvider>
  );
}

export default App;
