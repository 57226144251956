const compareObj = (a: any, b: any) => {
  const aKeys = Object.keys(a).sort();
  const bKeys = Object.keys(b).sort();
  if (aKeys.length !== bKeys.length) {
    return false;
  }
  if (aKeys.join('') !== bKeys.join('')) {
    return false;
  }
  for (let i = 0; i < aKeys.length; i++) {
    if (a[aKeys[i]] !== b[bKeys[i]]) {
      return false;
    }
  }
  return true;
};

export const resetPagination = (currentParamsAsObject: Object, cb: any) => {
  const ITEM = 'prevParamsAsObject';
  const currentParamsAsString = JSON.stringify(currentParamsAsObject);
  if (!localStorage.getItem(ITEM)) {
    localStorage.setItem(ITEM, currentParamsAsString);
  }
  const prevParamsAsObject = JSON.parse(localStorage.getItem(ITEM) || '');
  if (
    Object.keys(JSON.parse(localStorage.getItem(ITEM) || '')).length !==
      Object.keys(currentParamsAsObject).length ||
    !compareObj(prevParamsAsObject, currentParamsAsObject)
  ) {
    cb(1);
    localStorage.setItem(ITEM, currentParamsAsString);
  } else cb();
};
