export const EXAMPLE = {
  base: '/example',
};

export const AUTH = {
  RUT_VERIFICATION: '/auth/search',
  UPDATE_ACCOUNT: '/auth/activate-account',
  SEND_PASSWORD_RECOVERY_MAIL: '/auth/recovery-password',
  PASSWORD_CHANGE: '/auth/reset-password',
  LOGIN: '/auth/login',
  VALIDATE_RECOERY_TOKEN: '/auth/validate-link',
};

export const USERS = {
  GET_BY_ID: (id: string) => {
    return `/users/${id}`;
  },
  GET_ALL: '/users',
  ACTIVATE: '/users/activate',
  DEACTIVATE: '/users/deactivate',
  UPDATE_ADMINISTRATOR: (id: number) => {
    return `/users/${id}/administrator`;
  },
  ADD: '/personnel',
};

export const PROJECTS = {
  GET_BY_CODE: (projectCode: string) => {
    return `/financial-flow/${projectCode}/project`;
  },
  GET_ALL: '/assignments',
  GET_FILTERS: '/assignments/filters',
  GET_FILTERS_DEPARTMENT: (schoolId: string) => {
    return `/assignments/filters/${schoolId}/department`;
  },
  GET_ASSIGNED: (userId: string) => {
    return `/assignments/${userId}/assigned`;
  },
  ASSIGN: (id: string) => {
    return `/assignments/${id}/save`;
  },
  ASSIGN_ALL: (id: string) => {
    return `/assignments/${id}/save/all`;
  },
  UNASSIGN: (userId: string, projectCode: string) => {
    return `/assignments/${userId}/${projectCode}`;
  },
};

export const FINANCIAL = {
  GET_ASSIGNED: (userId: number) => {
    return `/financial-flow/${userId}/assigned`;
  },
  GET_FILTERS: (projectCode: string) => {
    return `/financial-flow/${projectCode}/filter`;
  },
  GET_ANNUAL_SUMMARY: (projectCode: string) => {
    return `/financial-flow/${projectCode}/annual-summary`;
  },
  GET_MONTHLY_SUMMARY: (projectCode: string) => {
    return `/financial-flow/${projectCode}/monthly-summary`;
  },
  GET_MONTHLY_DETAIL: (projectCode: string) => {
    return `/financial-flow/${projectCode}/monthly-detail`;
  },
  GET_MONTHLY_DETAIL_EXPORT_EXCEL: (projectCode: string) => {
    return `/report/financial-flow/${projectCode}/monthly-detail/export`;
  },
  GET_MONTHLY_DETAIL_EXPORT_PDF: (projectCode: string) => {
    return `/financial-flow/${projectCode}/monthly-detail/exportPDF`;
  },
};

export const COLLECTION_MANAGEMENT = {
  GET_ASSIGNED: (userId: number) => {
    return `/collection-management/${userId}/assigned`;
  },
  GET_SUMMARY_BY_PAYMENT_TYPE: (paymentType: string, projectCode: string) => {
    return `/collection-management/${projectCode}/payment-type/${paymentType}`;
  },
  GET_FILTER_BY_PAYMENT_TYPE: (paymentType: string, projectCode: string) => {
    return `/collection-management/${projectCode}/payment-type/${paymentType}/filter`;
  },
  GET_PAYMENT_SUMMARY_BY_PAYMENT_TYPE_EXPORT_EXCEL: (
    paymentType: string,
    projectCode: string,
  ) => {
    return `/report/collection-management/${projectCode}/payment-type/${paymentType}/excel`;
  },
  GET_PAYMENT_SUMMARY_BY_PAYMENT_TYPE_EXPORT_PDF: (
    paymentType: string,
    projectCode: string,
  ) => {
    return `/collection-management/${projectCode}/payment-type/${paymentType}/export/pdf`;
  },
};

export const NOTES_RECEIVABLE = {
  GET_ASSIGNED: (userId: number) => {
    return `/notes-receivable/${userId}/assigned`;
  },
  GET_DOCTYPE_SUMMARY_LIST: (projectCode: string) => {
    return `/notes-receivable/${projectCode}/doctype-summary`;
  },
  GET_DOCTYPE_DETAIL_SUMMARY_FINANCIAL: (projectCode: string) => {
    return `/notes-receivable/${projectCode}/doctype-summary/financial`;
  },
  GET_DOCTYPE_DETAIL_SUMMARY_LIST: (projectCode: string) => {
    return `/notes-receivable/${projectCode}/doctype-summary/detail`;
  },
  GET_FILTERS: (projectCode: string) => {
    return `/notes-receivable/${projectCode}/doctype-summary/filter`;
  },
  GET_DOCTYPE_DETAIL_SUMMARY_EXPORT_EXCEL: (projectCode: string) => {
    return `/report/notes-receivable/${projectCode}/excel`;
  },
  GET_DOCTYPE_DETAIL_SUMMARY_EXPORT_PDF: (projectCode: string) => {
    return `/notes-receivable/${projectCode}/export/pdf`;
  },
};

export const MONTHLY_RESULT = {
  GET_ASSIGNED: (userId: number) => {
    return `/monthly-result/${userId}/assigned`;
  },
};

export const PAYMENT_STATUS = {
  GET_ASSIGNED: (userId: number) => {
    return `/payment-status/${userId}/assigned`;
  },
  GET_SUMMARY_BY_PAYMENT_STATUS_TYPE: (
    paymentType: string,
    projectCode: string,
  ) => {
    return `/payment-status/${projectCode}/payment-state-type/${paymentType}`;
  },
  GET_FILTER_BY_PAYMENT_STATUS_TYPE: (
    paymentType: string,
    projectCode: string,
  ) => {
    return `/payment-status/${projectCode}/payment-state-type/${paymentType}/filter`;
  },
  GET_PAYMENT_SUMMARY_BY_PAYMENT_STATUS_TYPE_EXPORT_EXCEL: (
    paymentType: string,
    projectCode: string,
  ) => {
    return `/report/payment-status/${projectCode}/payment-state-type/${paymentType}/excel`;
  },
  GET_PAYMENT_SUMMARY_BY_PAYMENT_STATUS_TYPE_EXPORT_PDF: (
    paymentType: string,
    projectCode: string,
  ) => {
    return `/payment-status/${projectCode}/payment-state-type/${paymentType}/export/pdf`;
  },
};
