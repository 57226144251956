import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { Card } from 'reactstrap';
import { SidenavLayout as GlobalSidenavLayout } from '@octano/global-ui';
import RoutesByLayout from '../components/RoutesByLayout/RoutesByLayout';
import { PathsLayouts } from '../config/routes';
import {
  CategoryWithPermissions,
  LinkWithPermissions,
  SIDENAV_LINKS,
} from '../config/sidenav';
import { withLoggedInCheckRedirection } from '../hocs/withLoggedInCheckRedirection';
import useUserState from '../hooks/useUserState';
import TopBar from '../components/Topbar/TopBar';
import { withPermissionsCheckRedirection } from '../hocs/withPermissionsCheckRedirection';

interface TopBarTitlesDict {
  [route: string]: string;
}

export const TITLES_KEY_DICTIONARY: TopBarTitlesDict = {
  '/maintainers/users': 'usersModule',
  '/maintainers/users/:id/projects': 'usersModule',
  '/financial-flow/assigned-projects': 'financialFlowModule',
  '/financial-flow/:projectCode/annual-summary': 'financialFlowModule',
  '/financial-flow/:projectCode/annual-summary/detail-monthly':
    'financialFlowModule',
  '/collection-management/assigned-projects': 'collectionManagementModule',
  '/collection-management/:projectCode/summary-list':
    'collectionManagementModule',
  '/notes-receivable/assigned-projects': 'notesReceivableModule',
  '/notes-receivable/:projectCode/doctype-summary': 'notesReceivableModule',
  '/notes-receivable/:projectCode/doctype-summary/detail':
    'notesReceivableModule',
  '/monthly-result/assigned-projects': 'monthlyResultModule',
  '/payment-status/assigned-projects': 'paymentStatusModule',
  '/payment-status/:projectCode/summary-list': 'paymentStatusModule',
};

function SidenavLayout() {
  const { isAuthorizedTo } = useUserState();
  const { t } = useTranslation();
  const dictPrefix = 'sidenav.linkNames';

  // Verificamos si hay declarado un titulo exacto para esa ruta
  const exactMatch = useRouteMatch({
    path: Object.keys(TITLES_KEY_DICTIONARY),
    exact: true,
  });

  // Si no hay titulo para esa ruta exacta, usamos el del modulo en caso de existir
  const moduleMatch = useRouteMatch({
    path: Object.keys(TITLES_KEY_DICTIONARY),
  });

  // Si no hay un titulo registrado usamos el valor por defecto del diccionario
  const currentLocation = exactMatch?.path || moduleMatch?.path || 'default';

  const isCategory = (
    item: LinkWithPermissions | CategoryWithPermissions,
  ): item is CategoryWithPermissions => {
    return !!(item as CategoryWithPermissions).links;
  };

  const isAuthorizedLink = (link: LinkWithPermissions) => {
    if (!link.requiredPermissions?.length) return true;

    return isAuthorizedTo(link.requiredPermissions, link.allPermisionsRequired);
  };

  const getCategoryWithAuthorizedLinks = (
    category: CategoryWithPermissions,
  ) => {
    const authorizedLinks = category.links.filter(isAuthorizedLink);

    return { ...category, links: authorizedLinks };
  };

  const getItemWithoutPermissionsInfo = (
    item: LinkWithPermissions | CategoryWithPermissions,
  ) => {
    if (isCategory(item)) {
      const newLinks = item.links.map((link) => ({
        name: t(`${dictPrefix}.${link.name}`) ?? '',
        path: link.path,
      }));

      return { ...item, links: newLinks };
    } else {
      const link = item as LinkWithPermissions;
      const name = t(`${dictPrefix}.${link.name}`) ?? '';

      return { name, path: link.path, icon: link.icon };
    }
  };

  const getSidenavAuthorizedLinks = () => {
    const filteredItems = SIDENAV_LINKS.reduce((list, item) => {
      if (isCategory(item)) {
        const categoryWithFilteredLinks = getCategoryWithAuthorizedLinks(item);

        if (!categoryWithFilteredLinks.links.length) return list;

        return [...list, categoryWithFilteredLinks];
      }

      if (isAuthorizedLink(item as LinkWithPermissions)) {
        return [...list, item];
      }

      return list;
    }, [] as (LinkWithPermissions | CategoryWithPermissions)[]);

    return filteredItems.map(getItemWithoutPermissionsInfo);
  };

  return (
    <GlobalSidenavLayout links={getSidenavAuthorizedLinks()}>
      <div className="d-flex flex-column h-100">
        <TopBar title={TITLES_KEY_DICTIONARY[currentLocation]} />
        <div style={{ flexGrow: 1 }}>
          <Card className="p-4 m-3">
            <RoutesByLayout />
          </Card>
        </div>
      </div>
    </GlobalSidenavLayout>
  );
}

const SidenavLayoutWithLoginValidation = withLoggedInCheckRedirection({
  component: SidenavLayout,
  calculateRedirectTo: ({ isLogged, userData }) => {
    if (!isLogged && !userData) {
      return PathsLayouts.auth;
    }
  },
});

export default withPermissionsCheckRedirection({
  component: SidenavLayoutWithLoginValidation,
  calculateRedirectTo: ({
    userData,
    isAuthorized,
    authorizedModulesEntries,
    hasCheckedToken,
  }) => {
    if (hasCheckedToken && userData && !isAuthorized) {
      const match = authorizedModulesEntries?.find(
        ([key]) => key !== PathsLayouts.auth,
      );

      return match ? match[0] + match[1].defaultPath : undefined;
    }
  },
});
