import { ColumnTable, Icon } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DoctypeSummary } from '../../../../types/notes-receivable';
import { formatCurrency } from '../../../../utils/currency';

interface Props {
  viewDetailOnClick: (docType: string | undefined) => void;
}

export default function useTableColumns({
  viewDetailOnClick = () => null,
}: Props) {
  const { t } = useTranslation();

  const columns: ColumnTable<DoctypeSummary>[] = useMemo(() => {
    const tariffFormatter = formatCurrency({ symbol: false });
    return [
      {
        columnName: 'doctype',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.docType`),
        cellFormat: ({ row }) => {
          return row.doctype;
        },
      },
      {
        columnName: 'amount',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.count`),
        cellFormat: ({ row }) => {
          return tariffFormatter.format(Number(row.amount));
        },
      },
      {
        columnName: 'tax',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.tax`),
        cellFormat: ({ row }) => {
          return tariffFormatter.format(Number(row.tax));
        },
      },
      {
        columnName: 'reliable',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.reliable`),
        cellFormat: ({ row }) => {
          return tariffFormatter.format(Number(row.reliable));
        },
      },
      {
        columnName: 'totalBalance',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.totalBalance`),
        cellFormat: ({ row }) => {
          return tariffFormatter.format(Number(row.totalBalance));
        },
      },
      {
        columnName: 'action',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.action`),
        cellFormat: ({ row }) => {
          return (
            <span
              title={t(`common.terms.seeDetail`)}
              className="cursor-pointer"
              onClick={() => viewDetailOnClick(row.doctypeValue)}
            >
              <Icon name="eye" />
            </span>
          );
        },
      },
    ];
  }, [t, viewDetailOnClick]);

  return columns;
}
