export enum UserSessionEvent {
  EXPIRED = 'user-session-expired',
}

export interface Account {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  rut: string;
  birthday: string;
  lastLogin: string;
  isActive: boolean;
  roles: any;
  fullName: string;
}

export interface Permission {
  id: number;
  namePublic: string;
  name: PermissionName;
}

export enum PermissionName {
  exampleEdit = 'example.edit',
  exampleDelete = 'example.delete',
  userShow = 'user.show',
  financialShow = 'financial.show',
  notesReceivableShow = 'notesReceivable.show',
  collectionManagementShow = 'collectionManagement.show',
  monthlyResultShow = 'monthlyResult.show',
  paymentStatusShow = 'paymentStatus.show',
}

export interface UserData extends Account {
  permissions?: Permission['name'][];
}

export interface LoginFields {
  rut: string;
  password: string;
}

export interface AccountValidationFields {
  rut: string;
  recaptchaToken: string;
}

export interface updateAccountFormFields {
  phone: string;
  rut: string;
  password: string;
  passwordConfirm: string;
}

export interface updateAccountType {
  id: number;
  email?: string;
  phone: string;
  password: string;
  passwordConfirm: string;
}

export interface AccountRecoveryFields {
  rut: string;
}

export interface PasswordRecoveryFormFields {
  password: string;
  passwordConfirm: string;
}

export interface PasswordRecoveryFields {
  token: string;
  email: string;
  password: string;
  passwordConfirm: string;
}

export interface TokenStatus {
  expired: boolean;
}

export interface LoginResponse {
  access: {
    token: string;
  };
  account: Account;
  permissions: Permission[];
}
