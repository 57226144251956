import { addToast, Button, TextInput } from '@octano/global-ui';
import { useForm } from 'react-hook-form';
import { format } from 'rut.js';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { sendPasswordRecoveryEmail } from '../../../../api/requests/auth';
import { useValidations } from '../../../../hooks/useValidations';
import { AccountRecoveryFields } from '../../../../types/auth';

interface Props {
  setIsOpen: (param: boolean) => void;
  setRecoverySuccess: (param: boolean) => void;
}

const DEFAULT_VALUES = {
  rut: '',
};

export default function PasswordRecoveryMailForm({
  setIsOpen,
  setRecoverySuccess,
}: Props) {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<AccountRecoveryFields>({
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const { validateTextNotEmpty, validateRut } = useValidations();

  const handleSubmitRecovery = async (values: AccountRecoveryFields) => {
    const { error } = await sendPasswordRecoveryEmail(
      format(values.rut, { dots: false }),
    );

    if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`common.terms.unexpectedError`),
      });
    } else {
      setRecoverySuccess(true);
    }
  };

  return (
    <Row>
      <Col xs={12} className="pt-2">
        <form onSubmit={handleSubmit(handleSubmitRecovery)}>
          <Row>
            <Col xs={12} className="mb-2">
              <TextInput
                name="rut"
                type="text"
                control={control}
                label={t('common.terms.rut')}
                rules={{
                  validate: {
                    empty: validateTextNotEmpty,
                    rut: validateRut,
                  },
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} className="mb-4">
              <Button
                type="button"
                text={t(`common.actions.cancel`)}
                onClick={() => {
                  setIsOpen(false);
                }}
                fullwidth
                outlined
              />
            </Col>
            <Col sm={12} md={6}>
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
                text={t(`common.actions.recover`)}
                fullwidth
              />
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
}
