import { UserListResponse, UserResponse, UserSave } from '../../types/users';
import { USERS } from '../endpoints';
import request from '../request';

export interface ParametersPagination {
  page: string;
  itemsPerPage: string;
  search?: string;
}

/**
 * Obtiene un usuario a través de su Id
 */
export const getUser = (id: string) => {
  return request<UserResponse>(USERS.GET_BY_ID(id));
};

/**
 * Obtiene el listado de usuarios
 */
export const getUsers = (paginationParams: ParametersPagination) => {
  return request<UserListResponse>(USERS.GET_ALL, { params: paginationParams });
};

/**
 * Activa un usuario
 */
export const activateUser = (rut: string) => {
  return request(USERS.ACTIVATE, {
    method: 'POST',
    data: {
      rut,
    },
  });
};

/**
 * desactiva un usuario
 */
export const deactivateUser = (rut: string) => {
  return request(USERS.DEACTIVATE, {
    method: 'POST',
    data: {
      rut,
    },
  });
};

/**
 * cambia el estado de un usuario (activo / inactivo)
 */
export const changeUserState = (rut: string, currentState: boolean) => {
  return currentState ? deactivateUser(rut) : activateUser(rut);
};

/**
 * Permite cambiar un usuario a administrador
 */
export const setUserAsAdministrator = (
  id: number,
  isAdministrator: boolean,
) => {
  return request(USERS.UPDATE_ADMINISTRATOR(id), {
    method: 'POST',
    data: {
      isAdministrator,
    },
  });
};

/**
 * Permite agregar un nuevo usuario
 */
export const addUser = (data: UserSave) => {
  return request(USERS.ADD, {
    method: 'POST',
    data,
  });
};
