import {
  DoctypeFiltersResponse,
  SummaryDetailResponse,
} from '../../types/collection-management';
import { AssignedProjectsListReportResponse } from '../../types/projects';
import { COLLECTION_MANAGEMENT } from '../endpoints';
import request from '../request';

export interface ParametersPagination {
  page: string;
  itemsPerPage: string;
}

export interface ParametersPaymentsDetail {
  searchDocumentNum?: string;
  searchRut?: string;
  searchFromDate?: string;
  searchToDate?: string;
  searchDoctype?: string;
}

export interface ParametersProjectsAssignedPagination
  extends ParametersPagination,
    ParametersPaymentsDetail {
  search?: string;
}

/**
 * Obtiene el listado de proyectos asociados a un usuario
 */
export const getProjectsAssignedForCollectionManagement = (
  userId: number,
  paginationParams: ParametersProjectsAssignedPagination,
) => {
  return request<AssignedProjectsListReportResponse>(
    COLLECTION_MANAGEMENT.GET_ASSIGNED(userId),
    {
      params: paginationParams,
    },
  );
};

/**
 * Lista el resumen de pagos asociados a un proyecto por tipo de pago
 */
export const getPaymentsSummaryToProjectAssigned = (
  paymentType: string,
  projectCode: string,
  paginationParams: ParametersProjectsAssignedPagination,
) => {
  return request<SummaryDetailResponse>(
    COLLECTION_MANAGEMENT.GET_SUMMARY_BY_PAYMENT_TYPE(paymentType, projectCode),
    {
      params: paginationParams,
    },
  );
};

/**
 * Lista los tipos de documentos para filtrar
 */
export const getDocumentTypeFilter = (
  paymentType: string,
  projectCode: string,
) => {
  return request<DoctypeFiltersResponse[]>(
    COLLECTION_MANAGEMENT.GET_FILTER_BY_PAYMENT_TYPE(paymentType, projectCode),
  );
};

/**
 * Lista el resumen de pagos asociados a un proyecto por tipo de pago y lo exporta a EXCEL
 */
export const getPaymentsSummaryToExcel = async (
  paymentType: string,
  projectCode: string,
  params: ParametersPaymentsDetail,
) => {
  return await request(
    COLLECTION_MANAGEMENT.GET_PAYMENT_SUMMARY_BY_PAYMENT_TYPE_EXPORT_EXCEL(
      paymentType,
      projectCode,
    ),
    {
      responseType: 'blob',
      params,
    },
  );
};

/**
 * Lista el resumen de pagos asociados a un proyecto por tipo de pago y lo exporta a PDF
 */
export const getPaymentsSummaryToPdf = async (
  paymentType: string,
  projectCode: string,
  params: ParametersPaymentsDetail,
) => {
  return await request(
    COLLECTION_MANAGEMENT.GET_PAYMENT_SUMMARY_BY_PAYMENT_TYPE_EXPORT_PDF(
      paymentType,
      projectCode,
    ),
    {
      responseType: 'blob',
      params,
    },
  );
};
