import { ColumnTable } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DoctypeDetailSummary } from '../../../../types/notes-receivable';
import { formatCurrency } from '../../../../utils/currency';
import { formatDate } from '../../../../utils/date';

export default function useTableColumns() {
  const { t } = useTranslation();

  const columns: ColumnTable<DoctypeDetailSummary>[] = useMemo(() => {
    const tariffFormatter = formatCurrency({ symbol: false });
    return [
      {
        columnName: 'doctype',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.doctype`),
        cellFormat: ({ row }) => {
          return row.doctype;
        },
      },
      {
        columnName: 'rut',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.rut`),
        cellFormat: ({ row }) => {
          return row.rut;
        },
      },
      {
        columnName: 'name',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.name`),
        cellFormat: ({ row }) => {
          return row.name;
        },
      },
      {
        columnName: 'num',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.num`),
        cellFormat: ({ row }) => {
          return row.num;
        },
      },
      {
        columnName: 'fee',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.fee`),
        cellFormat: ({ row }) => {
          return row.fee;
        },
      },
      {
        columnName: 'season',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.season`),
        cellFormat: ({ row }) => {
          return formatDate(row.season, 'YYYY-MM-DD');
        },
      },
      {
        columnName: 'expirate',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.expirate`),
        cellFormat: ({ row }) => {
          return formatDate(row.expirate, 'YYYY-MM-DD');
        },
      },
      {
        columnName: 'amount',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.count`),
        cellFormat: ({ row }) => {
          return tariffFormatter.format(Number(row.amount));
        },
      },
      {
        columnName: 'tax',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.tax`),
        cellFormat: ({ row }) => {
          return tariffFormatter.format(Number(row.tax));
        },
      },
      {
        columnName: 'reliable',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.reliable`),
        cellFormat: ({ row }) => {
          return tariffFormatter.format(Number(row.reliable));
        },
      },
      {
        columnName: 'totalBalance',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.totalBalance`),
        cellFormat: ({ row }) => {
          return tariffFormatter.format(Number(row.totalBalance));
        },
      },
      {
        columnName: 'charge',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.charge`),
        cellFormat: ({ row }) => {
          return row.charge;
        },
      },
    ];
  }, [t]);

  return columns;
}
