import { addToast, SelectOptionType, Table } from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import {
  assignAllProjects,
  assignProjects,
  getProjects,
  getProjectsAssigned,
  ParametersPagination,
  ParametersProjects,
  unassignProjects,
} from '../../../../api/requests/projects';
import { getUser } from '../../../../api/requests/users';
import TableEmptyContent from '../../../../components/TableEmptyContent';
import { useLoading } from '../../../../hooks/useLoading';
import { usePagination } from '../../../../hooks/usePagination';
import useQuery from '../../../../hooks/useQuery';
import { Project } from '../../../../types/projects';
import { UserResponse } from '../../../../types/users';
import ButtonsAssignProjects from './parts/ButtonsAssignProjects';
import HeaderList from './parts/HeaderList';
import SearchFilters, { FilterFields } from './parts/SearchFilters';
import useProjectAssignedTableColumns from './parts/useProjectAssignedTableColumns';
import useProjectTableColumns from './parts/useProjectTableColumns';
import ConfirmationModal from './parts/ConfirmationModal';

enum AssignButtonLoadingState {
  ALL = 'all',
  ONE = 'one',
  NONE = 'none',
}

enum ConfirmationModalType {
  ASSIGN_ALL = 'assign_all',
  ASSIGN_SELECTED = 'assign_selected',
  UNASSIGN = 'unassign',
}

export default function ProjectsTable() {
  const { t } = useTranslation();
  const history = useHistory();
  const prefix = `views.projects`;

  const { loading, setLoading } = useLoading();
  const {
    loading: loadingProjectAssigned,
    setLoading: setLoadingProjectAssigned,
  } = useLoading();
  const [assignButtonLoading, setAssignButtonLoading] =
    useState<AssignButtonLoadingState>(AssignButtonLoadingState.NONE);
  const [unassignButtonLoading, setUnassignButtonLoading] = useState<string>();

  const { getParam } = useQuery();
  const { page, pagination, setCurrentPage, setPagination, itemsPerPage } =
    usePagination();
  const {
    page: pageProjectAssigned,
    pagination: paginationProjectAssigned,
    setCurrentPage: setCurrentPageProjectAssigned,
    setPagination: setPaginationProjectAssigned,
    itemsPerPage: itemsPerPageProjectAssigned,
  } = usePagination();

  /** get project manager data */
  const { id } = useParams<{ id: string }>();

  const getProjectManager = useCallback(async () => {
    const { data, error } = await getUser(id);

    if (error || !data) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`common.terms.unexpectedError`),
      });
      history.push(`/maintainers/users`);
    }

    setProjectManager(data!);
  }, [t, history, id]);

  useEffect(() => {
    getProjectManager();
  }, [getProjectManager]);
  const [projectManager, setProjectManager] = useState<UserResponse>();

  const [openConfirmationModal, setOpenConfirmationModal] = useState<{
    confirmationType: ConfirmationModalType;
    projectCode?: Project['projectCode'];
  }>();

  const showConfirmationModal = (
    confirmationType: ConfirmationModalType,
    projectCode?: Project['projectCode'],
  ) => {
    setOpenConfirmationModal({
      confirmationType,
      projectCode,
    });
  };

  const closeConfirmationModal = () => {
    setOpenConfirmationModal(undefined);
  };

  const showAssignAllProjectsConfirmationModal = () => {
    showConfirmationModal(ConfirmationModalType.ASSIGN_ALL);
  };

  const showAssignSelectedProjectsConfirmationModal = () => {
    showConfirmationModal(ConfirmationModalType.ASSIGN_SELECTED);
  };

  const showUnassignProjectConfirmationModal = (
    projectCode: Project['projectCode'],
  ) => {
    showConfirmationModal(ConfirmationModalType.UNASSIGN, projectCode);
  };

  const [projects, setProjects] = useState<Project[]>([]);
  const [projectsAssigned, setProjectsAssigned] = useState<Project[]>([]);

  /** Check all ítem */
  const [selectedPages, setSelectedPages] = useState<number[]>([]);
  const [selectedProjectCodes, setSelectedProjectCodes] = useState<string[]>(
    [],
  );

  const checkAll = useCallback(
    (checked: boolean, page: number) => {
      let newProjectCodes: string[] = [];
      let newSelectedPages: number[] = [];

      if (checked) {
        // check all projects
        newProjectCodes = [
          ...selectedProjectCodes.filter((currentCode) => {
            return !projects.find(
              (project) => project.projectCode === currentCode,
            );
          }),
          ...projects.map((project) => {
            return project.projectCode;
          }),
        ];

        // set check page
        newSelectedPages = [...selectedPages, page];
      } else {
        // uncheck all projects
        newProjectCodes = selectedProjectCodes.filter((currentCode) => {
          return !projects.find(
            (project) => project.projectCode === currentCode,
          );
        });

        // uncheck page
        newSelectedPages = selectedPages.filter((currentPage) => {
          return currentPage !== page;
        });
      }

      setSelectedProjectCodes(newProjectCodes);
      setSelectedPages(newSelectedPages);
    },
    [projects, selectedPages, selectedProjectCodes],
  );

  const checkOne = useCallback(
    (projectCode: Project['projectCode'], page: number) => {
      let newProjectCodes: string[] = [];
      let newSelectedPages: number[] = [];

      if (selectedProjectCodes.includes(projectCode)) {
        // check one project
        newProjectCodes = [
          ...selectedProjectCodes.filter((currentCode) => {
            return currentCode !== projectCode;
          }),
        ];
      } else {
        // uncheck one project
        newProjectCodes = [...selectedProjectCodes, projectCode];
      }

      // get project check
      const allChecked = projects.filter((project) => {
        return !newProjectCodes.includes(project.projectCode);
      });

      if (allChecked.length === 0) {
        // check page
        newSelectedPages = [...selectedPages, page];
      } else {
        // uncheck page
        newSelectedPages = selectedPages.filter((currentPage) => {
          return currentPage !== page;
        });
      }

      setSelectedProjectCodes(newProjectCodes);
      setSelectedPages(newSelectedPages);
    },
    [projects, selectedPages, selectedProjectCodes],
  );

  /** get list project */
  const columns = useProjectTableColumns({
    checkAll,
    checkOne,
    page,
    selectedPages,
    selectedProjectCodes,
  });

  const getProjectList = useCallback(
    async (pageNumber?: number) => {
      setLoading(true);

      const currentPage = pageNumber || page;
      let params: ParametersProjects = {
        userId: id,
        page: (currentPage - 1).toString(),
        itemsPerPage: itemsPerPage.toString(),
      };

      const currentSearch = getParam('search');
      if (currentSearch) {
        params.search = currentSearch;
      }

      const currentSchoolFilter = getParam('school');
      if (currentSchoolFilter) {
        params.school = currentSchoolFilter;
      }

      const currentDepartmentFilter = getParam('department');
      if (currentDepartmentFilter) {
        params.department = currentDepartmentFilter;
      }

      const { data, error } = await getProjects({
        ...params,
      });

      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`common.terms.unexpectedError`),
        });
        setLoading(false);
        return;
      }

      if (data) {
        setProjects(data.data);
        setPagination({
          totalItems: data.total,
          itemsPerPage: itemsPerPage,
          totalPages: data.totalPages,
          currentPage: currentPage,
          onChangePage: (pageNumber: number) => setCurrentPage(pageNumber),
        });
      }
      setLoading(false);
    },
    [
      t,
      setProjects,
      setLoading,
      setCurrentPage,
      setPagination,
      getParam,
      page,
      itemsPerPage,
      id,
    ],
  );

  /** get list of assigned projects */
  const getProjectAssignedList = useCallback(
    async (pageNumber?: number) => {
      setLoadingProjectAssigned(true);

      const currentPage = pageNumber || pageProjectAssigned;
      let params: ParametersPagination = {
        page: (currentPage - 1).toString(),
        itemsPerPage: itemsPerPageProjectAssigned.toString(),
      };

      const { data, error } = await getProjectsAssigned(id, {
        ...params,
      });

      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`common.terms.unexpectedError`),
        });
        setLoadingProjectAssigned(false);
        return;
      }

      if (data) {
        setProjectsAssigned(data.data);
        setPaginationProjectAssigned({
          totalItems: data.total,
          itemsPerPage: itemsPerPageProjectAssigned,
          totalPages: data.totalPages,
          currentPage: currentPage,
          onChangePage: (pageNumber: number) =>
            setCurrentPageProjectAssigned(pageNumber),
        });
      }
      setLoadingProjectAssigned(false);
    },
    [
      t,
      setProjectsAssigned,
      setLoadingProjectAssigned,
      setCurrentPageProjectAssigned,
      setPaginationProjectAssigned,
      pageProjectAssigned,
      itemsPerPageProjectAssigned,
      id,
    ],
  );

  const unassignedProjects = useCallback(
    async (projectCode: Project['projectCode']) => {
      setUnassignButtonLoading(projectCode);
      const { data, error } = await unassignProjects(id, projectCode);

      if (error || !data) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`common.terms.unexpectedError`),
        });
      } else {
        addToast({
          icon: 'check',
          color: 'success',
          text: t(`${prefix}.tableProjectsAssigned.successfullyRemovedProject`),
        });

        // reload list of projects
        getProjectList(1);
        setCurrentPage(1);

        // reload list of assigned projects
        await getProjectAssignedList(1);
        setCurrentPageProjectAssigned(1);

        setUnassignButtonLoading(undefined);
      }
    },
    [
      getProjectAssignedList,
      getProjectList,
      id,
      prefix,
      setCurrentPage,
      setCurrentPageProjectAssigned,
      t,
    ],
  );

  const columnsProjectsAssigned = useProjectAssignedTableColumns({
    unassignOnClick: showUnassignProjectConfirmationModal,
    unassignButtonLoading: unassignButtonLoading,
  });

  useEffect(() => {
    getProjectList();
  }, [getProjectList]);

  useEffect(() => {
    getProjectAssignedList();
  }, [getProjectAssignedList]);

  /** Assign projects */
  const handleAssignProjects = useCallback(async () => {
    setAssignButtonLoading(AssignButtonLoadingState.ONE);
    const { error } = await assignProjects(id, selectedProjectCodes);

    if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`common.terms.unexpectedError`),
      });
    } else {
      addToast({
        icon: 'check',
        color: 'success',
        text: t(`${prefix}.table.successfullyAssignedProjects`),
      });

      // clean the project selected
      setSelectedPages([]);
      setSelectedProjectCodes([]);

      // reload list of projects
      getProjectList(1);
      setCurrentPage(1);

      // reload list of assigned projects
      await getProjectAssignedList(1);
      setCurrentPageProjectAssigned(1);

      setAssignButtonLoading(AssignButtonLoadingState.NONE);
    }
  }, [
    getProjectAssignedList,
    getProjectList,
    id,
    prefix,
    selectedProjectCodes,
    setCurrentPage,
    setCurrentPageProjectAssigned,
    t,
  ]);

  /** Assign all projects */
  const handleAssignAllProjects = useCallback(async () => {
    setAssignButtonLoading(AssignButtonLoadingState.ALL);
    const { error } = await assignAllProjects({
      userId: id,
      searchText: getParam('search'),
    });

    if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`common.terms.unexpectedError`),
      });
    } else {
      addToast({
        icon: 'check',
        color: 'success',
        text: t(`${prefix}.table.successfullyAssignedProjects`),
      });

      // clean the project selected
      setSelectedPages([]);
      setSelectedProjectCodes([]);

      // reload list of projects
      getProjectAssignedList(1);
      await getProjectList(1);
      setAssignButtonLoading(AssignButtonLoadingState.NONE);
    }
  }, [getParam, getProjectAssignedList, getProjectList, id, prefix, t]);

  /** Search filters */
  const handleSearchFilters = useCallback(
    async (values: FilterFields) => {
      let newParams = {};

      Object.entries(values).forEach(([key, value]) => {
        if (!value) return;

        const { value: selectValue } = value as SelectOptionType;
        newParams = { ...newParams, [key]: selectValue || value };
      });

      const newUrlSearch = new URLSearchParams(newParams);

      setCurrentPage(1);
      history.push({
        pathname: `/maintainers/users/${id}/projects`,
        search: newUrlSearch.toString(),
      });
    },
    [history, id, setCurrentPage],
  );

  /** Handle confirmation modal */
  const handleConfirmationModal = useCallback(async () => {
    const confirmationFunctions = {
      [ConfirmationModalType.ASSIGN_ALL]: handleAssignAllProjects,
      [ConfirmationModalType.ASSIGN_SELECTED]: handleAssignProjects,
      [ConfirmationModalType.UNASSIGN]: unassignedProjects,
    };

    const handler =
      confirmationFunctions[openConfirmationModal?.confirmationType!];
    handler(openConfirmationModal?.projectCode!);
    closeConfirmationModal();
  }, [
    handleAssignAllProjects,
    handleAssignProjects,
    unassignedProjects,
    openConfirmationModal,
  ]);

  const confirmationModalMsgs = useMemo(() => {
    const confirmationMsgs = {
      [ConfirmationModalType.ASSIGN_ALL]: t(
        `${prefix}.table.assignAllProjectsConfirmation`,
      ),
      [ConfirmationModalType.ASSIGN_SELECTED]: t(
        `${prefix}.table.assignSelectedProjectsConfirmation`,
      ),
      [ConfirmationModalType.UNASSIGN]: t(
        `${prefix}.tableProjectsAssigned.unassignProjectConfirmation`,
      ),
      default: '',
    };

    return confirmationMsgs[
      openConfirmationModal?.confirmationType || 'default'
    ];
  }, [openConfirmationModal?.confirmationType, prefix, t]);

  return (
    <>
      <ConfirmationModal
        isOpen={!!openConfirmationModal}
        messageConfirmation={confirmationModalMsgs}
        onConfirm={handleConfirmationModal}
        onCancel={() => closeConfirmationModal()}
      />

      <HeaderList projectManagerName={projectManager?.name || ''} />
      <SearchFilters onSubmit={handleSearchFilters} />
      <Table
        borderless
        striped={false}
        columns={columns}
        data={projects}
        pagination={pagination}
        isLoadingResults={loading}
        noResultsText={
          <TableEmptyContent
            title={t(`${prefix}.table.emptyTitle`)}
            subtitle={t(`${prefix}.table.emptyDesc`)}
          />
        }
      />
      <ButtonsAssignProjects
        assignAllProjects={showAssignAllProjectsConfirmationModal}
        assignProjects={showAssignSelectedProjectsConfirmationModal}
        projectsCount={pagination?.totalItems}
        isCheckedOne={!!selectedProjectCodes.length}
        assignButtonIsLoading={
          assignButtonLoading === AssignButtonLoadingState.ONE
        }
        assignAllButtonIsLoading={
          assignButtonLoading === AssignButtonLoadingState.ALL
        }
      />

      <Row className="mt-4">
        <Col xs={6} md={3}>
          <h5 className="text-primary font-weight-bold text-uppercase">
            {t(`${prefix}.tableProjectsAssigned.assignedProjects`)}
          </h5>
        </Col>
      </Row>
      <Table
        borderless
        striped={false}
        columns={columnsProjectsAssigned}
        data={projectsAssigned}
        pagination={paginationProjectAssigned}
        isLoadingResults={loadingProjectAssigned}
        noResultsText={
          <TableEmptyContent
            title={t(`${prefix}.tableProjectsAssigned.emptyTitle`)}
            subtitle={t(`${prefix}.tableProjectsAssigned.emptyDesc`)}
          />
        }
      />
    </>
  );
}
