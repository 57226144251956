import { addToast, Button, Table } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { getDoctypeSummaryByProject } from '../../../api/requests/notes-receivable';
import { ParametersProjectsAssignedPagination } from '../../../api/requests/notes-receivable';
import { getProject } from '../../../api/requests/projects';
import TableEmptyContent from '../../../components/TableEmptyContent';
import { PathsLayouts } from '../../../config/routes';
import { useLoading } from '../../../hooks/useLoading';
import { usePagination } from '../../../hooks/usePagination';
import useQuery from '../../../hooks/useQuery';
import { DoctypeSummary } from '../../../types/notes-receivable';
import { Project } from '../../../types/projects';
import { resetPagination } from '../../../utils/resetPagination';
import ProjectInfo from './parts/ProjectInfo';
import SearchFilters from './parts/SearchFilters';
import useTableColumns from './parts/useTableColumns';

export const prefix = `views.notesReceivable.tableDoctypeSummaryList`;

export default function DoctypeSummaryTable() {
  const { t } = useTranslation();
  const history = useHistory();
  const { getParam, currentParamsAsObject } = useQuery();
  const { loading, setLoading } = useLoading();
  const { page, pagination, setCurrentPage, setPagination, itemsPerPage } =
    usePagination();

  const [summary, setSummary] = useState<DoctypeSummary[]>([]);
  const [projectData, setProjectData] = useState<Partial<Project>[]>([]);

  const { projectCode } = useParams<{ projectCode: string }>();

  /** Get project data */
  const getProjectData = useCallback(async () => {
    const { data, error } = await getProject(projectCode);

    if (error || !data) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`common.terms.unexpectedError`),
      });
    }

    setProjectData(data ? [data] : []);
  }, [t, projectCode]);

  useEffect(() => {
    getProjectData();
  }, [getProjectData]);

  const viewDetailOnClick = useCallback(
    (projectCode: string) => (doctype: string | undefined) => {
      history.push(
        `${PathsLayouts.notesReceivable}/${projectCode}/doctype-summary/detail?searchDoctype=${doctype}`,
      );
    },
    [history],
  );

  /** get table columns */
  const columns = useTableColumns({
    viewDetailOnClick: viewDetailOnClick(projectCode),
  });

  /** get doctype summary */
  const getDoctypeSummary = useCallback(
    async (pageNumber?: number) => {
      setLoading(true);
      const currentPage = pageNumber || page;
      let params: ParametersProjectsAssignedPagination = {
        page: (currentPage - 1).toString(),
        itemsPerPage: itemsPerPage.toString(),
      };

      const currentSearchFromDate = getParam('searchFromDate');
      const currentSearchToDate = getParam('searchToDate');

      if (currentSearchFromDate) params.searchFromDate = currentSearchFromDate;
      if (currentSearchToDate) params.searchToDate = currentSearchToDate;

      const { data, error } = await getDoctypeSummaryByProject(
        projectCode,
        params,
      );

      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`common.terms.unexpectedError`),
        });
        setLoading(false);
        return;
      }

      if (data) {
        setSummary(data.data);
        setPagination({
          totalItems: data.total,
          itemsPerPage: itemsPerPage,
          totalPages: data.totalPages,
          currentPage: currentPage,
          onChangePage: (pageNumber: number) => setCurrentPage(pageNumber),
        });
      }
      setLoading(false);
    },
    [
      t,
      setSummary,
      setLoading,
      setCurrentPage,
      setPagination,
      getParam,
      page,
      itemsPerPage,
      projectCode,
    ],
  );

  useEffect(() => {
    resetPagination(currentParamsAsObject, getDoctypeSummary);
  }, [getDoctypeSummary]);

  return (
    <>
      <Row className="d-flex align-items-center pb-2">
        <Col xs={12} md={6}>
          <Button
            icon="back"
            onClick={() => history.push(`${PathsLayouts.notesReceivable}`)}
            outlined
            rounded
            size="sm"
            text={t(`common.actions.backToThePreviousStep`)}
          />
        </Col>
      </Row>

      <Row className="mt-5 mb-4">
        <Col sm={12} md={12}>
          <ProjectInfo data={projectData} />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col sm={12} md={12}>
          <SearchFilters />
        </Col>
      </Row>

      <Row className="pt-5 pb-2">
        <Col sm={12} md={6}>
          <h5 className="text-primary font-weight-bold text-uppercase">
            {`${t(`${prefix}.title`)}`}
          </h5>
        </Col>
      </Row>

      <Table
        borderless
        striped={false}
        columns={columns}
        data={summary}
        pagination={pagination}
        isLoadingResults={loading}
        noResultsText={
          <TableEmptyContent
            title={t(`${prefix}.emptyTitle`)}
            subtitle={t(`${prefix}.emptyDesc`)}
          />
        }
      />
    </>
  );
}
