import {
  DoctypeDetailSummaryListResponse,
  DoctypeFiltersResponse,
  DoctypeSummary,
  DoctypeSummaryListResponse,
} from '../../types/notes-receivable';
import { AssignedProjectsListReportResponse } from '../../types/projects';
import { NOTES_RECEIVABLE } from '../endpoints';
import request from '../request';

export interface ParametersPagination {
  page: string;
  itemsPerPage: string;
}

export interface ParametersDoctypeSummary {
  searchFromDate?: string;
  searchToDate?: string;
}

export interface ParametersDoctypeDetailSummary
  extends ParametersDoctypeSummary {
  searchDoctype?: string;
  searchRut?: string;
}

export interface ParametersProjectsAssignedPagination
  extends ParametersPagination,
    ParametersDoctypeDetailSummary {
  search?: string;
}

/**
 * Obtiene el listado de proyectos asociados a un usuario
 */
export const getProjectsAssignedForNotesReceivable = (
  userId: number,
  paginationParams: ParametersProjectsAssignedPagination,
) => {
  return request<AssignedProjectsListReportResponse>(
    NOTES_RECEIVABLE.GET_ASSIGNED(userId),
    {
      params: paginationParams,
    },
  );
};

/**
 * Lista del resumen de documentos de un proyecto
 */
export const getDoctypeSummaryByProject = (
  projectCode: string,
  paginationParams: ParametersProjectsAssignedPagination,
) => {
  return request<DoctypeSummaryListResponse>(
    NOTES_RECEIVABLE.GET_DOCTYPE_SUMMARY_LIST(projectCode),
    {
      params: paginationParams,
    },
  );
};

/**
 * Detail financiero por tipo de documentos de un proyecto
 */
export const getFinancialSummaryByDoctype = (
  projectCode: string,
  params: ParametersDoctypeDetailSummary,
) => {
  return request<DoctypeSummary[]>(
    NOTES_RECEIVABLE.GET_DOCTYPE_DETAIL_SUMMARY_FINANCIAL(projectCode),
    {
      params,
    },
  );
};

/**
 * Lista del resumen detallado por documento de un proyecto por tipo de documento
 */
export const getDoctypeDetailSummaryByDoctype = (
  projectCode: string,
  paginationParams: ParametersProjectsAssignedPagination,
) => {
  return request<DoctypeDetailSummaryListResponse>(
    NOTES_RECEIVABLE.GET_DOCTYPE_DETAIL_SUMMARY_LIST(projectCode),
    {
      params: paginationParams,
    },
  );
};

export const getDoctypeDetailFilter = (projectCode: string) => {
  return request<DoctypeFiltersResponse[]>(
    NOTES_RECEIVABLE.GET_FILTERS(projectCode),
  );
};

export const getDoctypeDetailSummaryToExcel = async (
  projectCode: string,
  params: ParametersDoctypeDetailSummary,
) => {
  return await request(
    NOTES_RECEIVABLE.GET_DOCTYPE_DETAIL_SUMMARY_EXPORT_EXCEL(projectCode),
    {
      responseType: 'blob',
      params,
    },
  );
};

export const getDoctypeDetailSummaryToPdf = async (
  projectCode: string,
  params: ParametersDoctypeDetailSummary,
) => {
  return await request(
    NOTES_RECEIVABLE.GET_DOCTYPE_DETAIL_SUMMARY_EXPORT_PDF(projectCode),
    {
      responseType: 'blob',
      params,
    },
  );
};
