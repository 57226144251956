import {
  AnnualSummary,
  Filters, MonthlyDetailResponse,
  MonthlySummary
} from '../../types/financial-flow';
import { AssignedProjectsListReportResponse } from '../../types/projects';
import { FINANCIAL } from '../endpoints';
import request from '../request';

export interface ParametersPagination {
  page: string;
  itemsPerPage: string;
}

export interface ParametersProjectsAssignedPagination
  extends ParametersPagination {
  search?: string;
}

export interface ParametersMonthlyDetail {
  year: number;
  month: number;
}
export interface ParametersMonthlyDetailPagination
  extends ParametersPagination,
  ParametersMonthlyDetail { }

/**
 * Obtiene el listado de proyectos asociados a un usuario
 */
export const getProjectsAssignedForFinancialFlow = (
  userId: number,
  paginationParams: ParametersProjectsAssignedPagination,
) => {
  return request<AssignedProjectsListReportResponse>(
    FINANCIAL.GET_ASSIGNED(userId),
    {
      params: paginationParams,
    },
  );
};

/**
 * Obtiene los filtros para el resumen anual
 */
export const getFilterAnnualFinancialSummary = (projectCode: string) => {
  return request<Filters[]>(FINANCIAL.GET_FILTERS(projectCode));
};

/**
 * Lista el resumen anual
 */
export const getAnnualFinancialSummary = (
  projectCode: string,
  year: number,
) => {
  return request<AnnualSummary[]>(FINANCIAL.GET_ANNUAL_SUMMARY(projectCode), {
    params: { year },
  });
};

/**
 * Lista el resumen mensual
 */
export const getMonthlyFinancialSummary = (
  projectCode: string,
  year: number,
  month: number,
) => {
  return request<MonthlySummary>(FINANCIAL.GET_MONTHLY_SUMMARY(projectCode), {
    params: { year, month },
  });
};

/**
 * Lista detalle mensual
 */
export const getMonthlyFinancialDetail = (
  projectCode: string,
  params: ParametersMonthlyDetailPagination,
) => {
  return request<MonthlyDetailResponse>(
    FINANCIAL.GET_MONTHLY_DETAIL(projectCode),
    {
      params,
    },
  );
};

/**
 * Lista el detalle mensual para el exportable a excel
 */
export const getMonthlyFinancialDetailToExport = (
  projectCode: string,
  params: ParametersMonthlyDetail,
) => {
  return request(FINANCIAL.GET_MONTHLY_DETAIL_EXPORT_EXCEL(projectCode), {
    responseType: 'blob',
    params,
  });
};

/**
 * Solicita el comprobante de pago en formato PDF
 */
export const getMonthlyFinancialDetailToPdf = async (
  projectCode: string,
  params: ParametersMonthlyDetail,
) => {
  return await request(FINANCIAL.GET_MONTHLY_DETAIL_EXPORT_PDF(projectCode), {
    responseType: 'blob',
    params,
  });
};
