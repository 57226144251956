import { Button, OutlinedSelect } from '@octano/global-ui';
import { useEffect } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';

interface Props {
  onSubmit: (params: SubmitHandler<FieldValues>) => void;
}

export default function Exportable({ onSubmit }: Props) {
  const { t } = useTranslation();
  const { control, handleSubmit, formState, reset } = useForm();

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ exportable: '' });
    }
  }, [formState, reset]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="justify-content-end">
        <Col xs={12} md={6}>
          <OutlinedSelect
            name="exportable"
            placeholder={t(`common.terms.selectFormat`)}
            options={[
              {
                label: 'EXCEL',
                value: 'excel',
              },
              {
                label: 'PDF',
                value: 'pdf',
              },
            ]}
            control={control}
          />
        </Col>

        <Col xs={12} md={6}>
          <Button
            type="submit"
            icon="download"
            text={t(`common.actions.download`)}
            size="md"
            fullwidth
          />
        </Col>
      </Row>
    </Form>
  );
}
