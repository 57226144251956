import { AssignedProjectsListReportResponse } from '../../types/projects';
import { MONTHLY_RESULT } from '../endpoints';
import request from '../request';

export interface ParametersPagination {
  page: string;
  itemsPerPage: string;
}

export interface ParametersProjectsAssignedPagination
  extends ParametersPagination {
  search?: string;
}

/**
 * Obtiene el listado de proyectos asociados a un usuario
 */
export const getProjectsAssignedForMonthlyResult = (
  userId: number,
  paginationParams: ParametersProjectsAssignedPagination,
) => {
  return request<AssignedProjectsListReportResponse>(
    MONTHLY_RESULT.GET_ASSIGNED(userId),
    {
      params: paginationParams,
    },
  );
};
