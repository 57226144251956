import { addToast, Button, SelectOptionType, Table } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import {
  getFilterAnnualFinancialSummary,
  getMonthlyFinancialDetail,
  getMonthlyFinancialDetailToExport,
  getMonthlyFinancialDetailToPdf,
  getMonthlyFinancialSummary,
  ParametersMonthlyDetail,
  ParametersMonthlyDetailPagination,
} from '../../../api/requests/financial-flow';
import { getProject } from '../../../api/requests/projects';
import TableEmptyContent from '../../../components/TableEmptyContent';
import { PathsLayouts } from '../../../config/routes';
import { useLoading } from '../../../hooks/useLoading';
import { usePagination } from '../../../hooks/usePagination';
import useQuery from '../../../hooks/useQuery';
import { MonthlyDetail, MonthlySummary } from '../../../types/financial-flow';
import { Project } from '../../../types/projects';
import { downloadFromBlob } from '../../../utils/blob';
import { getMonthName, listMonthsName } from '../../../utils/date';
import { resetPagination } from '../../../utils/resetPagination';
import Exportable from './parts/Exportable';
import ExportableLoading from './parts/ExportableLoading';
import ProjectInfo from './parts/ProjectInfo';
import SearchFilters from './parts/SearchFilters';
import Summary from './parts/Summary';
import useTableColumns from './parts/useTableColumns';

export interface ListsDictionary {
  [index: string]: SelectOptionType[];
}

export default function MonthlyDetailTable() {
  const prefix = `views.financialFlow.tableMonthlyDetail`;

  const { t } = useTranslation();
  const history = useHistory();
  const { getParam, setParams, currentParamsAsObject } = useQuery();
  const { loading, setLoading } = useLoading();
  const [summary, setSummary] = useState<MonthlySummary[]>([]);
  const [detail, setDetail] = useState<MonthlyDetail[]>([]);
  const [projectData, setProjectData] = useState<Partial<Project>[]>([]);
  const [filtersList, setFiltersList] = useState<ListsDictionary>({
    years: [],
    months: [],
  });
  const { page, pagination, setCurrentPage, setPagination, itemsPerPage } =
    usePagination();

  const { loading: loadingExportFile, setLoading: setLoadingExportFile } =
    useLoading(false);

  const { projectCode } = useParams<{ projectCode: string }>();

  const year = Number(getParam('year')) || undefined;
  const month = Number(getParam('month')) || undefined;

  useEffect(() => {
    if (!year || !month) {
      history.push(
        `${PathsLayouts.financialFlow}/${projectCode}/annual-summary`,
      );
    }
  }, [history, month, projectCode, year]);

  /** Get project data */
  const getProjectData = useCallback(async () => {
    const { data, error } = await getProject(projectCode);

    if (error || !data) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`common.terms.unexpectedError`),
      });
    }

    setProjectData(data ? [data] : []);
  }, [t, projectCode]);

  useEffect(() => {
    if (year && month) {
      getProjectData();
    }
  }, [getProjectData, year, month]);

  /** get monthly summary */
  const getMonthlySummary = useCallback(async () => {
    const { data, error } = await getMonthlyFinancialSummary(
      projectCode,
      year!,
      month!,
    );

    if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`common.terms.unexpectedError`),
      });
      return;
    }

    if (data) {
      setSummary(data ? [data] : []);
    }
  }, [t, year, month, projectCode]);

  useEffect(() => {
    if (year && month) {
      resetPagination({ year, month }, getMonthlySummary);
    }
  }, [getMonthlySummary, month, year]);

  /** get table columns */
  const columns = useTableColumns();

  /** get monthly detail */
  const getMonthlyDetail = useCallback(
    async (pageNumber?: number) => {
      setLoading(true);
      const currentPage = pageNumber || page;
      const params: ParametersMonthlyDetailPagination = {
        year: Number(getParam('year')) || year!,
        month: Number(getParam('month')) || month!,
        page: (currentPage - 1).toString(),
        itemsPerPage: itemsPerPage.toString(),
      };

      const { data, error } = await getMonthlyFinancialDetail(
        projectCode,
        params,
      );

      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`common.terms.unexpectedError`),
        });
        setLoading(false);
        return;
      }

      if (data) {
        setDetail(data.data);
        setPagination({
          totalItems: data.total,
          itemsPerPage: itemsPerPage,
          totalPages: data.totalPages,
          currentPage: currentPage,
          onChangePage: (pageNumber: number) => setCurrentPage(pageNumber),
        });
      }

      setLoading(false);
    },
    [
      t,
      setLoading,
      setDetail,
      setPagination,
      setCurrentPage,
      getParam,
      year,
      month,
      page,
      itemsPerPage,
      projectCode,
    ],
  );

  useEffect(() => {
    if (year && month) {
      resetPagination(currentParamsAsObject, getMonthlyDetail);
    }
  }, [getMonthlyDetail, year, month, currentParamsAsObject]);

  /** Permite obtener la data para los filtros */
  const getDataForFilters = useCallback(async () => {
    const { data } = await getFilterAnnualFinancialSummary(projectCode);
    if (data) {
      // data para el filtro de años
      const parsedYearsData = data.map((year: { year: string }) => {
        return { value: year.year, label: year.year };
      });

      // data para el filtro de meses
      const parsedMonthsData = listMonthsName().map((month) => {
        return { value: month.key, label: month.value };
      });

      setFiltersList({ years: parsedYearsData, months: parsedMonthsData });
    }
  }, [projectCode, setFiltersList]);

  useEffect(() => {
    getDataForFilters();
  }, [getDataForFilters]);

  /** Datos por defecto para los filtros */
  const filtersDefaultValues = {
    year: Number(year),
    month: Number(month),
  };

  /** Permite enviar el formulario de filtros */
  const onSubmitFilters = useCallback(
    (values) => {
      const valuesNotEmpty: any = {};
      if (values.year) {
        valuesNotEmpty.year = values.year.value;
      }
      if (values.month) {
        valuesNotEmpty.month = values.month.value;
      }

      setParams(valuesNotEmpty);
    },
    [setParams],
  );

  /** Permite exportar el listado en el formato indicado */
  const xport = useCallback(
    async (values) => {
      if (!values || !values.exportable || !values.exportable.value) {
        return;
      }
      setLoadingExportFile(true);

      const params: ParametersMonthlyDetail = {
        year: year!,
        month: month!,
      };

      if (values.exportable.value === 'excel') {
        const { data, error } = await getMonthlyFinancialDetailToExport(
          projectCode,
          params,
        );
        if (error) {
          setLoadingExportFile(false);
          addToast({
            icon: 'error',
            color: 'danger',
            text: t(`common.terms.unexpectedError`),
          });
          return;
        }
        if (data) {
          downloadFromBlob(
            data,
            `resumen-mensual-${getMonthName(month!)}-${year}.xlsx`,
          );
          addToast({
            icon: 'success',
            color: 'success',
            text: t(`${prefix}.fileDownloadedSuccessfully`),
          });
        }
      }

      if (values.exportable.value === 'pdf') {
        const { data, error } = await getMonthlyFinancialDetailToPdf(
          projectCode,
          params,
        );
        if (error) {
          setLoadingExportFile(false);
          addToast({
            icon: 'error',
            color: 'danger',
            text: t(`common.terms.unexpectedError`),
          });
          return;
        }
        if (data) {
          downloadFromBlob(
            data,
            `FlujoFinanciero-${filtersDefaultValues.month}-${filtersDefaultValues.year}-${projectData[0].name}`,
          );
          addToast({
            icon: 'success',
            color: 'success',
            text: t(`${prefix}.fileDownloadedSuccessfully`),
          });
        }
      }

      setLoadingExportFile(false);
    },
    [
      t,
      setLoadingExportFile,
      year,
      month,
      filtersDefaultValues.month,
      filtersDefaultValues.year,
      projectCode,
      projectData,
      prefix,
    ],
  );

  return (
    <>
      <ExportableLoading isOpen={loadingExportFile} />
      <Row className="d-flex align-items-center pb-2">
        <Col xs={12} md={6}>
          <Button
            icon="back"
            onClick={() =>
              history.push(
                `${PathsLayouts.financialFlow}/${projectCode}/annual-summary`,
              )
            }
            outlined
            rounded
            size="sm"
            text={t(`common.actions.backToThePreviousStep`)}
          />
        </Col>

        <Col xs={12} md={6}>
          <SearchFilters
            filters={filtersList}
            onSubmit={onSubmitFilters}
            defaultValues={filtersDefaultValues}
          />
        </Col>
      </Row>

      <ProjectInfo data={projectData} />

      <Row className="pt-5 pb-2">
        <Col sm={12} md={6}>
          <h5 className="text-primary font-weight-bold text-uppercase">
            {`${t(`common.terms.summary`)} ${getMonthName(month!)} ${year}`}
          </h5>
        </Col>
      </Row>

      <Row className="pb-4 align-items-end">
        <Col sm={12} md={6}>
          <Summary data={summary} />
        </Col>

        <Col sm={12} md={6}>
          <Exportable onSubmit={xport} />
        </Col>
      </Row>

      <Table
        borderless
        striped
        columns={columns}
        data={detail}
        isLoadingResults={loading}
        pagination={pagination}
        noResultsText={
          <TableEmptyContent
            title={t(`${prefix}.emptyTitle`)}
            subtitle={t(`${prefix}.emptyDesc`)}
          />
        }
      />
    </>
  );
}
