import { addToast, Button, Table } from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  addUser,
  changeUserState,
  getUsers,
  ParametersPagination,
  setUserAsAdministrator,
} from '../../../../api/requests/users';
import TableEmptyContent from '../../../../components/TableEmptyContent';
import { useLoading } from '../../../../hooks/useLoading';
import { useQueryPagination } from '../../../../hooks/useQueryPagination';
import useQuery from '../../../../hooks/useQuery';
import { User, UserSave } from '../../../../types/users';
import ActivationConfirmationModal from './parts/ActivationConfirmationModal';
import SearchFilters from './parts/SearchFilters';
import useUserTableColumns from './parts/useUserTableColumns';
import UserAddModal from './parts/UserAddModal';
import { Col, Row } from 'reactstrap';
import { format } from 'rut.js';

enum ConfirmationModalType {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
  ACTIVATE_ADMINISTRATOR = 'activate_administrator',
  DEACTIVATE_ADMINISTRATOR = 'deactivate_administrator',
}

export default function UsersTable() {
  const { t } = useTranslation();

  const prefix = `views.users`;

  const { loading, setLoading } = useLoading();
  const { loading: loadingAddUser, setLoading: setLoadingAddUser } =
    useLoading(false);

  const { page, pagination, setCurrentPage, setPagination, ITEMS_PER_PAGE } =
    useQueryPagination();
  const { getParam } = useQuery();

  const [users, setUsers] = useState<User[]>([]);
  const [processingList, setProcessingList] = useState<string[]>([]);
  const [processingAdministrator, setProcessingAdministrator] =
    useState<number>();

  const [openAddUserModal, setOpenAddUserModal] = useState<boolean>(false);

  const [openConfirmationModal, setOpenConfirmationModal] = useState<{
    confirmationType: ConfirmationModalType;
    rut: User['rut'];
    shouldActivate?: boolean;
  }>();

  const showConfirmationModal = (
    confirmationType: ConfirmationModalType,
    rut: User['rut'],
    shouldActivate?: boolean,
  ) => {
    setOpenConfirmationModal({
      confirmationType,
      rut,
      shouldActivate,
    });
  };

  const showChangeStateConfirmationModal = (
    rut: User['rut'],
    shouldActivate?: boolean,
  ) => {
    showConfirmationModal(
      shouldActivate
        ? ConfirmationModalType.DEACTIVATE
        : ConfirmationModalType.ACTIVATE,
      rut,
      shouldActivate,
    );
  };

  const showChangeAdministratorConfirmationModal = (
    rut: User['rut'],
    shouldActivate?: boolean,
  ) => {
    showConfirmationModal(
      shouldActivate
        ? ConfirmationModalType.ACTIVATE_ADMINISTRATOR
        : ConfirmationModalType.DEACTIVATE_ADMINISTRATOR,
      rut,
      shouldActivate,
    );
  };

  const closeConfirmationModal = () => {
    setOpenConfirmationModal(undefined);
  };

  const handleChangeUserStatus = useCallback(
    async (rut: string, shouldActivate: boolean) => {
      setProcessingList([...processingList, rut]);
      const { data, error } = await changeUserState(rut, shouldActivate);

      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`common.terms.unexpectedError`),
        });
      } else {
        addToast({
          icon: 'check',
          color: 'success',
          text: !shouldActivate
            ? t(`${prefix}.table.activated`)
            : t(`${prefix}.table.deactivated`),
        });

        setUsers(
          users.map((user) => {
            if (user.rut === rut) {
              if (shouldActivate) {
                user.userId = 0;
                user.isAdministrator = false;
              } else {
                user.userId = data.id;
              }
            }

            return user;
          }),
        );
      }
      const filterElement = (elem: string) => elem !== rut;
      setProcessingList(processingList.filter(filterElement));
    },
    [t, setUsers, users, setProcessingList, processingList, prefix],
  );

  const handleChangeUserAdministrator = useCallback(
    async (rut: string, isAdministrator: boolean) => {
      const user = users.find((user) => user.rut === rut);
      if (!user) return false;

      const id = user.userId;

      setProcessingAdministrator(id);
      const { error } = await setUserAsAdministrator(id, isAdministrator);
      setProcessingAdministrator(undefined);

      setUsers(
        users.map((user) => {
          if (user.userId === id) user.isAdministrator = isAdministrator;
          return user;
        }),
      );

      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`common.terms.unexpectedError`),
        });
      } else {
        addToast({
          icon: 'check',
          color: 'success',
          text: !isAdministrator
            ? t(`${prefix}.table.administratorDeactivated`)
            : t(`${prefix}.table.administratorActivate`),
        });
      }
    },
    [users, t, prefix],
  );

  const columns = useUserTableColumns({
    processingList,
    processingAdministrator,
    onUserStatusChange: showChangeStateConfirmationModal,
    onChangeAdministrator: showChangeAdministratorConfirmationModal,
  });

  const getUserList = useCallback(async () => {
    setLoading(true);

    let params: ParametersPagination = {
      page: (page - 1).toString(),
      itemsPerPage: ITEMS_PER_PAGE.toString(),
    };

    const currentSearch = getParam('search');
    if (currentSearch) {
      params.search = currentSearch;
    }

    const { data, error } = await getUsers({
      ...params,
    });

    if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`common.terms.unexpectedError`),
      });
      setLoading(false);
      return;
    }

    if (data) {
      setUsers(data.data);
      setPagination({
        totalItems: data.total,
        itemsPerPage: ITEMS_PER_PAGE,
        totalPages: data.totalPages,
        currentPage: page,
        onChangePage: (pageNumber: number) => setCurrentPage(pageNumber),
      });
    }
    setLoading(false);
  }, [
    t,
    setUsers,
    setLoading,
    setCurrentPage,
    setPagination,
    page,
    getParam,
    ITEMS_PER_PAGE,
  ]);

  useEffect(() => {
    getUserList();
  }, [getUserList]);

  /** Handle confirmation modal */
  const handleConfirmationModal = useCallback(async () => {
    const confirmationFunctions = {
      [ConfirmationModalType.ACTIVATE]: handleChangeUserStatus,
      [ConfirmationModalType.DEACTIVATE]: handleChangeUserStatus,
      [ConfirmationModalType.ACTIVATE_ADMINISTRATOR]:
        handleChangeUserAdministrator,
      [ConfirmationModalType.DEACTIVATE_ADMINISTRATOR]:
        handleChangeUserAdministrator,
    };

    const handler =
      confirmationFunctions[openConfirmationModal?.confirmationType!];
    handler(
      openConfirmationModal?.rut!,
      openConfirmationModal?.shouldActivate!,
    );
    closeConfirmationModal();
  }, [
    openConfirmationModal,
    handleChangeUserStatus,
    handleChangeUserAdministrator,
  ]);

  const confirmationModalMsgs = useMemo(() => {
    const confirmationMsgs = {
      [ConfirmationModalType.ACTIVATE]: t(
        `${prefix}.table.activationConfirmationUser`,
      ),
      [ConfirmationModalType.DEACTIVATE]: t(
        `${prefix}.table.deactivationConfirmationUser`,
      ),
      [ConfirmationModalType.ACTIVATE_ADMINISTRATOR]: t(
        `${prefix}.table.confirmationActivateUserAdministrator`,
      ),
      [ConfirmationModalType.DEACTIVATE_ADMINISTRATOR]: t(
        `${prefix}.table.confirmationDeactivateUserAdministrator`,
      ),
      default: '',
    };

    return confirmationMsgs[
      openConfirmationModal?.confirmationType || 'default'
    ];
  }, [openConfirmationModal?.confirmationType, prefix, t]);

  const handleModalAddUser = useCallback((resetForm?: any) => {
    if (typeof resetForm === 'function') {
      resetForm();
    }

    setOpenAddUserModal((open) => !open);
  }, []);

  const handleSubmitFormAddUser = useCallback(
    async (values: UserSave) => {
      // rut format
      values.rut = format(values.rut, { dots: false });

      setLoadingAddUser(true);
      const { error } = await addUser(values);
      setLoadingAddUser(false);

      if (error) {
        let errorMessage = t(`common.terms.unexpectedError`);

        // RUT exists
        if (error?.status === 400) {
          errorMessage = t(`${prefix}.add.registeredRutError`);
        }

        addToast({
          icon: 'error',
          color: 'danger',
          text: errorMessage,
        });
      } else {
        addToast({
          icon: 'check',
          color: 'success',
          text: t(`${prefix}.add.registeredSuccessfully`),
        });

        handleModalAddUser();
        getUserList();
      }
    },
    [setLoadingAddUser, t, prefix, handleModalAddUser, getUserList],
  );

  return (
    <>
      <ActivationConfirmationModal
        isOpen={!!openConfirmationModal}
        messageConfirmation={confirmationModalMsgs}
        onConfirm={handleConfirmationModal}
        onCancel={() => closeConfirmationModal()}
      />
      <UserAddModal
        isOpen={openAddUserModal}
        isLoading={loadingAddUser}
        onCancel={handleModalAddUser}
        onSubmit={handleSubmitFormAddUser}
      />
      <Row className="d-flex align-items-center pb-2">
        <SearchFilters />
        <Col xs={12} md={3} className="pt-2 ml-auto">
          <Button
            type="button"
            text={t(`common.actions.addNewUser`)}
            size="md"
            fullwidth
            onClick={handleModalAddUser}
          />
        </Col>
      </Row>
      <Table
        borderless
        striped={false}
        height={700}
        columns={columns}
        data={users}
        pagination={pagination}
        isLoadingResults={loading}
        noResultsText={
          <TableEmptyContent
            title={t(`${prefix}.table.emptyTitle`)}
            subtitle={t(`${prefix}.table.emptyDesc`)}
          />
        }
      />
    </>
  );
}
