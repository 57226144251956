import {
  Button,
  DateInput,
  OutlinedSelect,
  SearchBox,
  SelectOptionType,
} from '@octano/global-ui';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import useQuery, { Parameters } from '../../../../hooks/useQuery';
import { useForm } from 'react-hook-form';
import { formatDate } from '../../../../utils/date';
import { prefix } from '..';

export interface ListsDictionary {
  [index: string]: SelectOptionType[];
}
interface Props {
  idTab: string | number;
  filters: ListsDictionary;
}

export default function SearchFilters({ idTab, filters }: Props) {
  const { t } = useTranslation();

  const { getParam, setParams } = useQuery();
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      searchRut: getParam('searchRut') || '',
      searchFromDate: getParam('searchFromDate') || '',
      searchToDate: getParam('searchToDate') || '',
      searchDoctype: getParam('searchDoctype') || '',
    },
  });

  const onSubmit = useCallback(
    (values) => {
      const valuesNotEmpty: Parameters = {};

      if (values.searchRut) {
        valuesNotEmpty.searchRut = values.searchRut;
      }
      if (values.searchFromDate) {
        valuesNotEmpty.searchFromDate = formatDate(
          values.searchFromDate,
          'YYYY-MM-DD',
        );
      }
      if (values.searchToDate) {
        valuesNotEmpty.searchToDate = formatDate(
          values.searchToDate,
          'YYYY-MM-DD',
        );
      }
      if (values.searchDoctype) {
        valuesNotEmpty.searchDoctype = values.searchDoctype.value;
      }
      setParams(valuesNotEmpty);
    },
    [setParams],
  );

  const onClear = useCallback(() => {
    setParams({});
    setValue('searchRut', '');
    setValue('searchFromDate', '');
    setValue('searchToDate', '');
    setValue('searchDoctype', '');
  }, [setParams, setValue]);

  useEffect(() => {
    onClear();
  }, [onClear, idTab]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="d-flex align-items-center pb-2">
          <Col xs={12} sm={6} md={4} lg={2}>
            <OutlinedSelect
              name="searchDoctype"
              label={t(`${prefix}.documentType`)}
              placeholder={t(`${prefix}.searchDocumentType`)}
              isClearable={true}
              options={filters.documentTypes}
              control={control}
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={2}>
            <SearchBox
              name="searchRut"
              label={t(`${prefix}.rut`)}
              placeholder={t(`${prefix}.searchRut`)}
              control={control}
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={2}>
            <DateInput
              name="searchFromDate"
              label={t(`${prefix}.dateFrom`)}
              control={control}
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={2}>
            <DateInput
              name="searchToDate"
              label={t(`${prefix}.dateTo`)}
              control={control}
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={2} className="pt-2">
            <Button
              type="submit"
              text={t(`common.actions.search`)}
              size="md"
              fullwidth
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={2} className="pt-2">
            <Button
              text={t(`common.actions.clear`)}
              size="md"
              fullwidth
              outlined
              onClick={onClear}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
}
