import {
  FilterListResponse,
  Project,
  ProjectsListResponse,
} from '../../types/projects';
import { PROJECTS } from '../endpoints';
import request from '../request';

export interface ParametersPagination {
  page: string;
  itemsPerPage: string;
}

export interface ParametersProjects extends ParametersPagination {
  userId: string;
  search?: string;
  school?: string;
  department?: string;
}

/**
 * Obtiene un proyecto a través de su código
 */
export const getProject = (projectCode: string) => {
  return request<Partial<Project>>(PROJECTS.GET_BY_CODE(projectCode));
};

/**
 * Obtiene el listado de proyectos
 */
export const getProjects = (paginationParams: ParametersProjects) => {
  return request<ProjectsListResponse>(PROJECTS.GET_ALL, {
    params: paginationParams,
  });
};

/**
 * Obtiene el listado de proyectos asociados a un usuario
 */
export const getProjectsAssigned = (
  userId: string,
  paginationParams: ParametersPagination,
) => {
  return request<ProjectsListResponse>(PROJECTS.GET_ASSIGNED(userId), {
    params: paginationParams,
  });
};

/**
 * Asocia los proyectos seleccionados a un usuario
 */
export const assignProjects = (userId: string, projectsCode: string[]) => {
  return request(PROJECTS.ASSIGN(userId), {
    method: 'POST',
    data: {
      projectsCode,
    },
  });
};

/**
 * Asocia todos los proyectos listados a un usuario
 */
export const assignAllProjects = (params: {
  userId: string;
  searchText?: string;
}) => {
  const { userId, searchText } = params;

  return request(PROJECTS.ASSIGN_ALL(userId), {
    method: 'POST',
    data: {
      searchText,
    },
  });
};

/**
 * Lista los datos para el filtro de búsqueda
 */
export const getFilters = () => {
  return request<FilterListResponse>(PROJECTS.GET_FILTERS);
};

/**
 * Lista los departamentos osociados a una facultad, para el filtro de búsqueda
 */
export const getFiltersDepartment = (schoolId: string) => {
  return request(PROJECTS.GET_FILTERS_DEPARTMENT(schoolId));
};

/**
 * Desasocia un proyecto de un usuario
 */
export const unassignProjects = (userId: string, projectCode: string) => {
  return request(PROJECTS.UNASSIGN(userId, projectCode), {
    method: 'DELETE',
  });
};
