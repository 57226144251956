import { ColumnTable, Icon } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SummaryPayment } from '../../../../types/payment-status';
import { formatCurrency } from '../../../../utils/currency';
import { formatDate } from '../../../../utils/date';

export default function useTableColumns() {
  const { t } = useTranslation();

  const columns: ColumnTable<SummaryPayment>[] = useMemo(() => {
    const tariffFormatter = formatCurrency({ symbol: false });

    return [
      {
        columnName: 'date',
        headerText: t(`common.terms.date`),
        cellFormat: ({ row }) => {
          return formatDate(row.date, 'YYYY-MM-DD');
        },
      },
      {
        columnName: 'doctype',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.document`),
      },
      {
        columnName: 'docnum',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.docNum`),
      },
      {
        columnName: 'rut',
        headerText: t(`common.terms.referenceRut`),
        cellFormat: ({ row }) => {
          return row.rut;
        },
      },
      {
        columnName: 'amount',
        headerText: t(`common.terms.count`),
        cellFormat: ({ row }) => {
          return tariffFormatter.format(Number(row.amount));
        },
      },
      {
        columnName: 'observations',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        headerText: t(`common.terms.observations`),
        cellFormat: ({ row }) => {
          return (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => null}
              title="detail"
            >
              <Icon className="mt-0" name="eye" size="24px" color="secondary" />
            </div>
          );
        },
      },
    ];
  }, [t]);

  return columns;
}
