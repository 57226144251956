import { Button, SearchBox } from '@octano/global-ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import useQuery from '../../../../hooks/useQuery';
import { useForm } from 'react-hook-form';

export default function SearchFilters() {
  const { t } = useTranslation();
  const prefix = `views.notesReceivable.tableAssignedProjects`;

  const { getParam, setParams } = useQuery();
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: { search: getParam('search') },
  });

  const onSubmit = useCallback(
    (values) => {
      if (values.search) {
        setParams(values);
      }
    },
    [setParams],
  );

  const onClear = useCallback(() => {
    setParams({ search: '' });
    setValue('search', '');
  }, [setParams, setValue]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="d-flex align-items-center pb-2">
          <Col xs={12} md={3}>
            <SearchBox
              name="search"
              label={t(`${prefix}.searchLabel`)}
              placeholder={t(`${prefix}.searchPlaceholder`)}
              control={control}
            />
          </Col>
          <Col xs={12} md={2} className="pt-2">
            <Button
              type="submit"
              text={t(`common.actions.search`)}
              size="md"
              fullwidth
            />
          </Col>
          <Col xs={12} md={2} className="pt-2">
            <Button
              text={t(`common.actions.clear`)}
              size="md"
              fullwidth
              outlined
              onClick={onClear}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
}
