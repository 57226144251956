import { Table } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { DoctypeSummary } from '../../../../types/notes-receivable';
import { formatCurrency } from '../../../../utils/currency';

interface Props {
  data: DoctypeSummary[];
  loading?: boolean;
}

export default function Summary({ data, loading }: Props) {
  const { t } = useTranslation();
  const tariffFormatter = formatCurrency({});

  return (
    <Table
      borderless={false}
      color="transparent"
      size="small"
      isLoadingResults={loading}
      columns={[
        {
          columnName: 'doctype',
          headerText: t('common.terms.doc'),
          thClassName: 'font-weight-bold text-uppercase',
          cellFormat: ({ row }) => {
            return row.doctype;
          },
        },
        {
          columnName: 'amount',
          headerText: t('common.terms.amount2'),
          thClassName: 'font-weight-bold text-uppercase',
          cellFormat: ({ row }) => {
            return tariffFormatter.format(Number(row.amount));
          },
        },
        {
          columnName: 'tax',
          headerText: t('common.terms.tax'),
          thClassName: 'font-weight-bold text-uppercase',
          cellFormat: ({ row }) => {
            return tariffFormatter.format(Number(row.tax));
          },
        },
        {
          columnName: 'reliable',
          headerText: t('common.terms.reliable'),
          thClassName: 'font-weight-bold text-uppercase',
          cellFormat: ({ row }) => {
            return tariffFormatter.format(Number(row.reliable));
          },
        },
        {
          columnName: 'totalBalance',
          headerText: t('common.terms.totalBalance'),
          thClassName: 'font-weight-bold text-uppercase',
          cellFormat: ({ row }) => {
            return tariffFormatter.format(Number(row.totalBalance));
          },
        },
      ]}
      data={data}
    />
  );
}
