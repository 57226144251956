import { Table } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Project } from '../../../../types/projects';

interface Props {
  data: Partial<Project>[];
}

export default function ProjectInfo({ data }: Props) {
  const { t } = useTranslation();

  return (
    <Table
      borderless={false}
      color="transparent"
      isLoadingResults={!data.length}
      columns={[
        {
          columnName: 'name',
          headerText: t('common.terms.projectName'),
          thClassName: 'pl-4',
          tdClassName: 'pl-4',
          width: '80%',
        },
        {
          columnName: 'projectCode',
          headerText: t('common.terms.projectCode'),
          thClassName: 'text-center',
          tdClassName: 'text-center',
        },
      ]}
      data={data}
    />
  );
}
