import React, { useRef, useState } from 'react';
import { addToast, Alert, Button, TextInput } from '@octano/global-ui';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';
import { format } from 'rut.js';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { getRutVerification } from '../../../api/requests/auth';
import { RECAPTCHA_SITE_KEY } from '../../../config/constants';
import { PathsLayouts } from '../../../config/routes';
import { useValidations } from '../../../hooks/useValidations';
import { AccountValidationFields } from '../../../types/auth';
import AccountContainer from '../AccountContainer';

const DEFAULT_VALUES = {
  rut: '',
  recaptchaToken: '',
};

export default function AccountValidationForm() {
  const { t } = useTranslation();
  const prefix = `views.account.create`;
  const history = useHistory();
  const [error, setError] = useState<Boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  let recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<AccountValidationFields>({
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const resetToken = () => {
    reset(
      {
        recaptchaToken: '',
      },
      {
        keepErrors: true,
        keepDirty: true,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false,
      },
    );
    recaptchaRef.current?.reset();
  };

  const { validateTextNotEmpty, validateRut } = useValidations();

  const handleSubmitValidation = async (values: AccountValidationFields) => {
    setError(false);

    const { data, error } = await getRutVerification(
      format(values.rut, { dots: false }),
      values.recaptchaToken,
    );

    if (error) {
      if (error?.status === 400) {
        setError(true);
        setErrorMessage(t(`${prefix}.rutAlreadyExist`));
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`common.terms.unexpectedError`),
        });
      }
      resetToken();
      return;
    }

    if (data?.id) {
      history.push({
        pathname: `${PathsLayouts.auth}/account/update/${data.id}`,
        state: {
          email: data?.email,
          rut: format(values.rut, {
            dots: false,
          }),
        },
      });
    } else {
      setError(true);
      setErrorMessage(t(`${prefix}.rutNotFound`));
    }

    resetToken();
  };

  return (
    <AccountContainer
      title={t(`${prefix}.title`)}
      subTitle={t(`${prefix}.desc`)}
      hasLinkUndercard
      wrapped
    >
      <Row>
        <Col xs={12} className="pt-2">
          <form onSubmit={handleSubmit(handleSubmitValidation)}>
            <Row>
              <Col xs={12}>
                {error && (
                  <Alert
                    color="danger"
                    icon="error"
                    size="sm"
                    text={errorMessage}
                    toggle={() => {
                      setError(false);
                    }}
                    noFill
                  />
                )}
                <TextInput
                  name="rut"
                  type="text"
                  control={control}
                  label={t('common.terms.rut')}
                  rules={{
                    validate: {
                      empty: validateTextNotEmpty,
                      rut: validateRut,
                    },
                  }}
                />
              </Col>
            </Row>
            <Row className="pt-4 mb-3">
              <Col>
                <Controller
                  control={control}
                  name="recaptchaToken"
                  rules={{ required: true }}
                  render={({
                    field: { onChange },
                    fieldState: { invalid },
                  }) => {
                    return (
                      <div>
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={RECAPTCHA_SITE_KEY}
                          onChange={onChange}
                          hl={t('common.terms.language_code')}
                          size="normal"
                        />
                        {invalid && (
                          <small className="g-input-error form-text text-danger">
                            {t('common.validations.required')}
                          </small>
                        )}
                      </div>
                    );
                  }}
                />
              </Col>
            </Row>
            <div className="py-4">
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
                text={t(`common.actions.next`)}
                fullwidth
              />
            </div>
          </form>
        </Col>
      </Row>
    </AccountContainer>
  );
}
