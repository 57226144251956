import { addToast, Button, Table } from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { prefix } from '..';
import {
  getDocumentTypeFilter,
  getPaymentsSummaryToExcel,
  getPaymentsSummaryToPdf,
  getPaymentsSummaryToProjectAssigned,
  ParametersPaymentsDetail,
  ParametersProjectsAssignedPagination,
} from '../../../../api/requests/payment-status';
import { getProject } from '../../../../api/requests/projects';
import TableEmptyContent from '../../../../components/TableEmptyContent';
import { PathsLayouts } from '../../../../config/routes';
import { useLoading } from '../../../../hooks/useLoading';
import { usePagination } from '../../../../hooks/usePagination';
import useQuery from '../../../../hooks/useQuery';
import { SummaryPayment } from '../../../../types/payment-status';
import { Project } from '../../../../types/projects';
import { downloadFromBlob } from '../../../../utils/blob';
import { resetPagination } from '../../../../utils/resetPagination';
import ProjectInfo from '../../../financialFlow/annualSummary/parts/ProjectInfo';
import Exportable from './Exportable';
import ExportableLoading from './ExportableLoading';
import SearchFilters from './SearchFilters';
import useTableColumns from './useTableColumns';

interface Props {
  idTab: string;
  projectCode: string;
}

export default function DetailView({ idTab, projectCode }: Props) {
  const { t } = useTranslation();
  const { getParam, currentParamsAsObject } = useQuery();
  const history = useHistory();

  const { loading: loadingExportFile, setLoading: setLoadingExportFile } =
    useLoading(false);
  const { loading, setLoading } = useLoading();
  const { page, pagination, setCurrentPage, setPagination, itemsPerPage } =
    usePagination();

  const [projectData, setProjectData] = useState<Partial<Project>[]>([]);
  const [projects, setProjects] = useState<SummaryPayment[]>([]);
  const [doctypesFilters, setDoctypesFilters] = useState<any[]>([]);

  /** Datos por defecto para los filtros */
  const getDoctypesFilters = useCallback(async () => {
    const { data, error } = await getDocumentTypeFilter(idTab, projectCode);

    if (error || !data) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`common.terms.unexpectedError`),
      });
    }

    setDoctypesFilters(data ? data : []);
  }, [idTab, projectCode, t]);

  useEffect(() => {
    getDoctypesFilters();
  }, [getDoctypesFilters]);

  /** Get project data */
  const getProjectData = useCallback(async () => {
    const { data, error } = await getProject(projectCode);

    if (error || !data) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`common.terms.unexpectedError`),
      });
    }
    setProjectData(data ? [data] : []);
  }, [t, projectCode]);
  useEffect(() => {
    getProjectData();
  }, [getProjectData, projectCode]);

  /** get list project */
  const columns = useTableColumns();

  const getProjectList = useCallback(
    async (pageNumber?: number) => {
      setLoading(true);

      const currentPage = pageNumber || page;
      let params: ParametersProjectsAssignedPagination = {
        page: (currentPage - 1).toString(),
        itemsPerPage: itemsPerPage.toString(),
      };

      const currentSearchRut = getParam('searchRut');
      const currentSearchFromDate = getParam('searchFromDate');
      const currentSearchToDate = getParam('searchToDate');
      const currentDocumentType = getParam('searchDoctype');
      if (currentSearchRut) params.searchRut = currentSearchRut;
      if (currentSearchFromDate) params.searchFromDate = currentSearchFromDate;
      if (currentSearchToDate) params.searchToDate = currentSearchToDate;
      if (currentDocumentType) params.searchDoctype = currentDocumentType;

      const { data, error } = await getPaymentsSummaryToProjectAssigned(
        idTab,
        projectCode,
        params,
      );

      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`common.terms.unexpectedError`),
        });
        setLoading(false);
        return;
      }

      if (data) {
        setProjects(data.data);
        setPagination({
          totalItems: data.total,
          itemsPerPage: itemsPerPage,
          totalPages: data.totalPages,
          currentPage: currentPage,
          onChangePage: (pageNumber: number) => setCurrentPage(pageNumber),
        });
      }
      setLoading(false);
    },
    [
      t,
      setProjects,
      setLoading,
      setCurrentPage,
      setPagination,
      getParam,
      page,
      itemsPerPage,
      idTab,
      projectCode,
    ],
  );

  useEffect(() => {
    resetPagination(currentParamsAsObject, getProjectList);
  }, [getProjectList, currentParamsAsObject]);

  /** function to export excel or pdf document from list data */
  const xport = useCallback(
    async (values) => {
      if (!values || !values.exportable || !values.exportable.value) {
        return;
      }
      setLoadingExportFile(true);
      let params: ParametersPaymentsDetail = {};

      const currentSearchDocumentNum = getParam('searchDocumentNum');
      const currentSearchRut = getParam('searchRut');
      const currentSearchFromDate = getParam('searchFromDate');
      const currentSearchToDate = getParam('searchToDate');
      const currentDocumentType = getParam('searchDoctype');
      if (currentSearchDocumentNum)
        params.searchDocumentNum = currentSearchDocumentNum;
      if (currentSearchRut) params.searchRut = currentSearchRut;
      if (currentSearchFromDate) params.searchFromDate = currentSearchFromDate;
      if (currentSearchToDate) params.searchToDate = currentSearchToDate;
      if (currentDocumentType) params.searchDoctype = currentDocumentType;

      if (values.exportable.value === 'excel') {
        const { data, error } = await getPaymentsSummaryToExcel(
          idTab,
          projectCode,
          params,
        );
        if (error) {
          setLoadingExportFile(false);
          addToast({
            icon: 'error',
            color: 'danger',
            text: t(`common.terms.unexpectedError`),
          });
          return;
        }
        if (data) {
          downloadFromBlob(
            data,
            `Reporte_Estado_De_Pago_De_Documentos-${projectCode}-${Date.now()}.xlsx`,
          );
          addToast({
            icon: 'success',
            color: 'success',
            text: t(`${prefix}.fileDownloadedSuccessfully`),
          });
        }
      }
      if (values.exportable.value === 'pdf') {
        const { data, error } = await getPaymentsSummaryToPdf(
          idTab,
          projectCode,
          params,
        );
        if (error) {
          setLoadingExportFile(false);
          addToast({
            icon: 'error',
            color: 'danger',
            text: t(`common.terms.unexpectedError`),
          });
          return;
        }
        if (data) {
          downloadFromBlob(
            data,
            `Reporte_Gestión_de_Cobranza-${projectCode}-${Date.now()}`,
          );
          addToast({
            icon: 'success',
            color: 'success',
            text: t(`${prefix}.fileDownloadedSuccessfully`),
          });
        }
      }
      setLoadingExportFile(false);
    },
    [t, setLoadingExportFile, getParam, idTab, projectCode],
  );

  return (
    <>
      <ExportableLoading isOpen={loadingExportFile} />
      <Row className="d-flex align-items-center mt-4">
        <Col xs={12} md={6}>
          <Button
            icon="back"
            onClick={() => history.push(`${PathsLayouts.paymentStatus}`)}
            outlined
            rounded
            size="sm"
            text={t(`common.actions.backToThePreviousStep`)}
          />
        </Col>
      </Row>

      <Row className="mt-5 mb-4">
        <Col sm={12} md={12}>
          <ProjectInfo data={projectData} />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col sm={12} md={12}>
          <SearchFilters
            idTab={idTab}
            filters={{
              documentTypes: doctypesFilters,
            }}
          />
        </Col>
      </Row>

      <Row className="mb-4 align-items-end justify-content-end">
        <Col sm={12} md={8} lg={5}>
          <Exportable onSubmit={xport} />
        </Col>
      </Row>

      <Table
        borderless
        striped={false}
        columns={columns}
        data={projects}
        pagination={pagination}
        isLoadingResults={loading}
        noResultsText={
          <TableEmptyContent
            title={t(`${prefix}.emptyTitle`)}
            subtitle={t(`${prefix}.emptyDesc`)}
          />
        }
      />
    </>
  );
}
