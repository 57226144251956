import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { format } from 'rut.js';
import {
  addToast,
  Alert,
  AuthContainer,
  Button,
  Logo,
  TextInput,
} from '@octano/global-ui';
import { ReactComponent as LoginImage } from '../../../assets/svg/login_image.svg';
import { ReactComponent as LogoPersonnel } from '../../../assets/svg/logo_personnel_white.svg';
import { ReactComponent as LogoPersonnelColor } from '../../../assets/svg/logo_personnel_color.svg';
import { LoginFields } from '../../../types/auth';
import { useValidations } from '../../../hooks/useValidations';
import routes, { PathsLayouts } from '../../../config/routes';
import PasswordRecoveryModal from '../recovery/PasswordRecoveryModal';
import { accountLogin } from '../../../api/requests/auth';
import useUserState from '../../../hooks/useUserState';
import { setJWT } from '../../../utils/auth';

export default function Login() {
  const { t } = useTranslation();
  const prefix = `views.account.login`;
  const history = useHistory();
  const [error, setError] = useState<Boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isRecoveryModalOpen, setIsRecoveryModalOpen] =
    useState<boolean>(false);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<LoginFields>({
    mode: 'onChange',
  });

  const { setUserData } = useUserState();
  const { msgValidations, validateTextNotEmpty, validateRut } =
    useValidations();

  const handleLogin = async (values: LoginFields) => {
    setError(false);
    const { data, error } = await accountLogin({
      rut: format(values.rut, { dots: false }),
      password: values.password,
    });

    if (error) {
      if (error?.status === 400) {
        setError(true);
        setErrorMessage(t(`${prefix}.wrongCredentials`));
      } else {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t(`common.terms.unexpectedError`),
        });
      }
      return;
    }

    if (data) {
      const permissionsNames =
        data.permissions?.map((permission) => permission.name) || [];

      setUserData({ ...data.account, permissions: permissionsNames });
      setJWT(data.access.token);

      history.push(
        `${PathsLayouts.maintainers}/${
          routes[PathsLayouts.maintainers].defaultPath
        }`,
      );
    }
  };

  return (
    <React.Fragment>
      <AuthContainer
        brandLogoWhite={<LogoPersonnel />}
        loginImage={<LoginImage />}
        brandLogoColor={<LogoPersonnelColor />}
      >
        <Row>
          <Col xs={12} className="text-center">
            <Logo
              alt="logo"
              style={{ maxHeight: 80 }}
              src="/tenant/logo.svg"
              fallbackSrc="/tenant/logo.png"
            />
          </Col>
          <Col xs={12} className="mt-4 mb-5 d-flex justify-content-center">
            <p className="text-center pt-4 mb-0 fs-16">
              {t(`${prefix}.welcome`)}
            </p>
          </Col>

          <Col xs={12} className="pt-2">
            <form onSubmit={handleSubmit(handleLogin)}>
              <Row>
                <Col xs={12}>
                  {error && (
                    <Alert
                      color="danger"
                      icon="error"
                      size="sm"
                      text={errorMessage}
                      toggle={() => {
                        setError(false);
                      }}
                      noFill
                    />
                  )}
                  <TextInput
                    name="rut"
                    type="text"
                    control={control}
                    label={t('common.terms.rut')}
                    rules={{
                      validate: {
                        rut: validateRut,
                        notEmpty: validateTextNotEmpty,
                      },
                    }}
                  />
                </Col>
              </Row>
              <Row className="pt-4">
                <Col xs={12}>
                  <TextInput
                    name="password"
                    type="password"
                    control={control}
                    label={t('common.terms.password')}
                    rules={{
                      required: msgValidations.required,
                    }}
                  />
                </Col>
              </Row>
              <div className="py-4">
                <Button
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  text={t(`common.actions.getIn`)}
                  fullwidth
                />
              </div>
            </form>
          </Col>
          <Col xs={12} className="text-center">
            <button
              className="mb-0 text-center anchor-like "
              onClick={() => setIsRecoveryModalOpen(true)}
            >
              {t(`common.terms.forgotPassword`)}
            </button>
          </Col>
          <Col xs={12} className="pt-3 pb-2">
            <p className="text-center mb-0">
              {t('common.terms.noAccount')}

              <Link
                className="mb-0 ml-1 text-center anchor-like"
                to={`${PathsLayouts.auth}/account/validation`}
              >
                {t(`common.terms.createHere`)}
              </Link>
            </p>
          </Col>
        </Row>
      </AuthContainer>
      <PasswordRecoveryModal
        isOpen={isRecoveryModalOpen}
        setIsOpen={setIsRecoveryModalOpen}
      />
    </React.Fragment>
  );
}
