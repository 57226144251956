import RoutesByLayout from '../components/RoutesByLayout/RoutesByLayout';
import { PathsLayouts } from '../config/routes';
import { withLoggedInCheckRedirection } from '../hocs/withLoggedInCheckRedirection';

function AuthLayout() {
  return (
    <div>
      <RoutesByLayout />
    </div>
  );
}

export default withLoggedInCheckRedirection({
  component: AuthLayout,
  calculateRedirectTo: ({ userData, isLogged }) => {
    return isLogged && userData ? PathsLayouts.financialFlow : undefined;
  },
});
