import { Button } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PathsLayouts } from '../../../config/routes';
import AccountContainer from '../AccountContainer';

export default function UpdateAccountSuccess() {
  const { t } = useTranslation();
  const prefix = `views.account.create`;
  const history = useHistory();

  return (
    <AccountContainer
      icon="success"
      title={t(`${prefix}.successTitle`)}
      subTitle={t(`${prefix}.successDesc`)}
      wrapped
    >
      <div className="py-4">
        <Button
          type="button"
          text={t(`common.actions.logIn`)}
          onClick={() => history.push(`${PathsLayouts.auth}`)}
          fullwidth
        />
      </div>
    </AccountContainer>
  );
}
