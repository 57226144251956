import { PaginationType } from '@octano/global-ui';
import { useCallback, useMemo, useState } from 'react';
import useQuery from './useQuery';
import { ITEMS_PER_PAGE } from '../config/constants';

const FIRST_PAGE = 1;

export const useQueryPagination = () => {
  const [pagination, setPagination] = useState<PaginationType>();

  const { getParam, setParams, currentParamsAsObject } = useQuery();

  const page: number = useMemo(() => {
    const param = parseInt(getParam('page') || FIRST_PAGE.toString());
    return isNaN(param) ? FIRST_PAGE : param;
  }, [getParam]);

  const setCurrentPage = useCallback(
    (pageNumber: number = 0) => {
      setParams({ ...currentParamsAsObject, page: pageNumber.toString() });
    },
    [setParams, currentParamsAsObject],
  );

  return {
    page,
    setCurrentPage,
    pagination,
    setPagination,
    ITEMS_PER_PAGE,
  };
};
