import { Button } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

interface Props {
  assignProjects: () => void;
  assignAllProjects: () => void;
  projectsCount?: number;
  isCheckedOne: boolean;
  assignButtonIsLoading: boolean;
  assignAllButtonIsLoading: boolean;
}

export default function ButtonsAssignProjects({
  assignProjects = () => null,
  assignAllProjects = () => null,
  projectsCount,
  isCheckedOne,
  assignButtonIsLoading,
  assignAllButtonIsLoading,
}: Props) {
  const { t } = useTranslation();
  const prefix = `views.projects.table`;

  return (
    <Row className="pt-3 justify-content-end">
      <Col md={2}>
        <Button
          outlined
          fullwidth
          text={t(`${prefix}.assignAllProjects`)}
          disabled={!projectsCount || assignButtonIsLoading}
          onClick={() => assignAllProjects()}
          loading={assignAllButtonIsLoading}
        />

        <p className="text-lowercase">
          {projectsCount
            ? `${projectsCount} ${
                projectsCount > 1
                  ? t(`common.terms.projects`)
                  : t(`common.terms.project`)
              }`
            : ''}
        </p>
      </Col>
      <Col md={2}>
        <Button
          fullwidth
          text={t(`${prefix}.assignSelectedProjects`)}
          disabled={!isCheckedOne || assignAllButtonIsLoading}
          loading={assignButtonIsLoading}
          onClick={() => assignProjects()}
        />
      </Col>
    </Row>
  );
}
