import {
  Button,
  OutlinedSelect,
  SearchBox,
  SelectOptionType,
} from '@octano/global-ui';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import {
  getFilters,
  getFiltersDepartment,
} from '../../../../../api/requests/projects';
import useQuery from '../../../../../hooks/useQuery';

export type FilterFields = {
  search: string;
  school: SelectOptionType | null;
  department: SelectOptionType | null;
};
export interface ListsDictionary {
  [index: string]: SelectOptionType[];
}

interface Props {
  onSubmit: (params: any) => void;
}

export default function SearchFilters({ onSubmit }: Props) {
  const { t } = useTranslation();
  const prefix = `views.projects.table`;
  const urlSearchParams = useQuery();

  const { control, handleSubmit, setValue, getValues, watch } =
    useForm<FilterFields>({
      mode: 'onChange',
    });

  const [filtersList, setFiltersList] = useState<ListsDictionary>({
    school: [],
    department: [],
  });

  const [filtersAreLoading, setFiltersAreLoading] = useState<boolean>();

  const isLoadFilterQuery = useRef(false);

  const getDataForFilters = useCallback(async () => {
    const { data } = await getFilters();
    if (data) {
      const parsedSchoolData = data.schools.map(
        (school: { id: number; name: string }) => {
          return { value: school.id, label: school.name };
        },
      );

      let parsedDepartmentData = [];
      const school = urlSearchParams.query.getAll('school');
      if (school.length > 0) {
        setFiltersAreLoading(true);
        const { data } = await getFiltersDepartment(school.toString());
        setFiltersAreLoading(false);
        if (data) {
          parsedDepartmentData = data.map(
            (department: { id: number; name: string }) => {
              return { value: department.id, label: department.name };
            },
          );
        }
      }

      setFiltersList({
        school: parsedSchoolData,
        department: parsedDepartmentData,
      });
    }
  }, [urlSearchParams.query]);

  useEffect(() => {
    getDataForFilters();
  }, [getDataForFilters]);

  const getFiltersDepartmentBySchool = useCallback(
    async (schoolId?: string) => {
      if (!schoolId) {
        setFiltersList((current) => {
          return { school: current.school, department: [] };
        });

        return;
      }

      setFiltersAreLoading(true);
      const { data } = await getFiltersDepartment(schoolId);
      setFiltersAreLoading(false);
      if (data) {
        const parsedDepartmentData = data.map(
          (department: { id: number; name: string }) => {
            return { value: department.id, label: department.name };
          },
        );

        setFiltersList((current) => {
          return { school: current.school, department: parsedDepartmentData };
        });
      }
    },
    [],
  );

  useEffect(() => {
    watch((value, { name, type }) => {
      if (name === 'school' && type === 'change') {
        setValue('department', null);
        getFiltersDepartmentBySchool(value.school?.value.toString());
      }
    });
  }, [getFiltersDepartmentBySchool, setValue, watch]);

  const setFilterValuesQueryString = useCallback(async () => {
    const selectionFilters = ['school', 'department'];

    // solo se cargan una vez los filtros desde la URL
    if (isLoadFilterQuery.current || filtersList.department.length === 0)
      return false;

    urlSearchParams.query.forEach(async (value, key) => {
      if (!value) return false;

      const keyWithType = key as keyof FilterFields;

      const existsValue = getValues(keyWithType);
      if (existsValue) return false;

      if (selectionFilters.includes(keyWithType)) {
        const match = filtersList[keyWithType].find(
          (elem) => elem.value === value,
        );

        setValue(keyWithType, match ?? null);
      } else {
        setValue(keyWithType, value);
      }
    });
    isLoadFilterQuery.current = true;
  }, [filtersList, getValues, setValue, urlSearchParams.query]);

  useEffect(() => {
    setFilterValuesQueryString();
  }, [setFilterValuesQueryString]);

  const clearFilters = useCallback(() => {
    urlSearchParams.query.forEach(async (value, key) => {
      const keyWithType = key as keyof FilterFields;
      setValue(keyWithType, null);
    });

    onSubmit(getValues());
  }, [getValues, onSubmit, setValue, urlSearchParams.query]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="d-flex align-items-center pb-2">
        <Col xs={12} md={3}>
          <OutlinedSelect
            name="school"
            label={t(`common.terms.school`)}
            options={filtersList.school}
            control={control}
          />
        </Col>

        <Col xs={12} md={3}>
          <OutlinedSelect
            name="department"
            label={t(`common.terms.department`)}
            control={control}
            options={filtersList.department}
          />
        </Col>
        <Col xs={12} md={4}>
          <SearchBox
            name="search"
            label={t(`common.terms.projectName`)}
            placeholder={t(`${prefix}.searchPlaceholder`)}
            control={control}
          />
        </Col>
        <Col xs={12} md={1} className="pt-2">
          <Button
            type="submit"
            text={t(`common.actions.search`)}
            size="md"
            fullwidth
            loading={filtersAreLoading}
          />
        </Col>
        <Col xs={12} md={1} className="pt-2">
          <Button
            type="button"
            text={t(`common.actions.clear`)}
            size="md"
            fullwidth
            outlined
            onClick={() => clearFilters()}
          />
        </Col>
      </Row>
    </Form>
  );
}
