import { ColumnTable, CheckInput } from '@octano/global-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Project } from '../../../../../types/projects';

interface Props {
  page: number;
  checkAll: (checked: boolean, page: number) => void;
  checkOne: (projectCode: Project['projectCode'], page: number) => void;
  selectedPages: number[];
  selectedProjectCodes: Project['projectCode'][];
}

export default function useProjectTableColumns({
  checkAll,
  checkOne,
  page,
  selectedPages,
  selectedProjectCodes,
}: Props) {
  const { t } = useTranslation();

  const columns: ColumnTable<Project>[] = useMemo(() => {
    return [
      {
        columnName: 'code',
        thClassName: 'text-center',
        tdClassName: 'text-center fw-700',
        headerText: t(`common.terms.costCenter`),
      },
      {
        columnName: 'school',
        headerText: t(`common.terms.school`),
      },
      {
        columnName: 'department',
        headerText: t(`common.terms.department`),
        cellFormat: ({ row }) => row.department || 'N/A',
      },
      {
        columnName: 'name',
        headerText: t(`common.terms.projectName`),
      },
      {
        columnName: 'isAssigned',
        thClassName: 'd-flex justify-content-end',
        tdClassName: 'd-flex justify-content-end',
        headerText: (
          <CheckInput
            name="isAssignedAll"
            label=""
            value={!!selectedPages.includes(page)}
            onChange={(el) => checkAll(el.target.checked, page)}
          />
        ),
        cellFormat: ({ row }) => {
          return (
            <CheckInput
              name={`isAssigned-${row.projectCode}`}
              label=""
              defaultValue={false}
              value={selectedProjectCodes.includes(row.projectCode)}
              onChange={() => checkOne(row.projectCode, page)}
            />
          );
        },
      },
    ];
  }, [t, selectedPages, page, checkAll, selectedProjectCodes, checkOne]);

  return columns;
}
