import { Box, Button } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

interface Props {
  projectManagerName: string;
}

export default function HeaderList({ projectManagerName }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const prefix = `views.projects.table`;

  return (
    <>
      <Row className="justify-content-between align-items-center">
        <Col xs={12} md={6}>
          <Button
            icon="back"
            onClick={() => history.push(`/maintainers/users`)}
            outlined
            rounded
            size="sm"
            text={t(`common.actions.backToTheList`)}
          />
        </Col>
        <Col xs={12} md={4} className="pt-1">
          <Box
            body={`${projectManagerName}`}
            color="primary"
            title={t(`common.terms.projectManager`)}
            variant="outlined"
            fullwidth
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={12} md={6}>
          <h5 className="text-primary font-weight-bold text-uppercase">
            {t(`${prefix}.availableProjects`)}
          </h5>
        </Col>
      </Row>
    </>
  );
}
