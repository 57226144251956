import { Tab, TabPanel, TabPanels, Tabs, TabsList } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import DetailView from './parts/detailView';

export const prefix = `views.paymentStatus.tableSummaryList`;

export const SUMMARY_LIST_TABS_ROUTES = {
  PENDING_TAB_ID: 'pending',
  PAYMENTS_TAB_ID: 'payments',
  REJECTED_TAB_ID: 'rejected',
} as const;

const { PENDING_TAB_ID, PAYMENTS_TAB_ID, REJECTED_TAB_ID } =
  SUMMARY_LIST_TABS_ROUTES;

export default function PaymentStatusSummaryList() {
  const { t } = useTranslation();
  const { projectCode } = useParams<{ projectCode: string }>();

  return (
    <>
      <Tabs>
        <TabsList>
          <Tab>{t(`${prefix}.${PENDING_TAB_ID}.tabTitle`)}</Tab>
          <Tab id={PAYMENTS_TAB_ID}>
            {t(`${prefix}.${PAYMENTS_TAB_ID}.tabTitle`)}
          </Tab>
          <Tab id={REJECTED_TAB_ID}>
            {t(`${prefix}.${REJECTED_TAB_ID}.tabTitle`)}
          </Tab>
        </TabsList>

        <TabPanels>
          <TabPanel>
            <DetailView idTab={PENDING_TAB_ID} projectCode={projectCode} />
          </TabPanel>
          <TabPanel id={PAYMENTS_TAB_ID}>
            <DetailView idTab={PAYMENTS_TAB_ID} projectCode={projectCode} />
          </TabPanel>
          <TabPanel id={REJECTED_TAB_ID}>
            <DetailView idTab={REJECTED_TAB_ID} projectCode={projectCode} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}
