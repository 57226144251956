import { Button, Loading, TextInput } from '@octano/global-ui';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import {
  passwordRecovery,
  validateRecoveryToken,
} from '../../../api/requests/auth';
import { PathsLayouts } from '../../../config/routes';
import { useLoading } from '../../../hooks/useLoading';
import useQuery from '../../../hooks/useQuery';
import { useValidations } from '../../../hooks/useValidations';
import { PasswordRecoveryFormFields } from '../../../types/auth';
import AccountContainer from '../AccountContainer';

export default function PasswordRecoveryForm() {
  const { t } = useTranslation();
  const prefix = `views.account.recovery.password`;
  const history = useHistory();
  const { query } = useQuery();
  const { loading, setLoading } = useLoading();
  const token: string = useMemo(() => query.get('token') || '', [query]);
  const email: string = useMemo(() => query.get('email') || '', [query]);

  const {
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm<PasswordRecoveryFormFields>({
    mode: 'onChange',
    defaultValues: {
      password: undefined,
      passwordConfirm: undefined,
    },
  });

  const password = watch('password');

  const {
    validateTextNotEmpty,
    validatePassword,
    validateRepeatPassword,
    msgValidations,
  } = useValidations();

  const handleSubmitRecovery = async (values: PasswordRecoveryFormFields) => {
    const { error } = await passwordRecovery({
      token,
      email,
      password: values.password,
      passwordConfirm: values.passwordConfirm,
    });

    history.push({
      pathname: `${PathsLayouts.auth}/account/recovery/finish`,
      state: {
        expired: !!error,
      },
    });
  };

  const validateTokenExpiration = useCallback(async () => {
    const { error } = await validateRecoveryToken(email, token);

    if (error) {
      history.push({
        pathname: `${PathsLayouts.auth}/account/recovery/finish`,
        state: {
          expired: true,
        },
      });
    } else {
      setLoading(false);
    }
  }, [email, token, history, setLoading]);

  if (!token || !email) {
    history.push(`${PathsLayouts.auth}/login`);
  }

  useEffect(() => {
    validateTokenExpiration();
  }, [validateTokenExpiration]);

  if (loading)
    return (
      <div className="account-recovery">
        <Loading textBody="" insideCard className=" loading" />
      </div>
    );

  return (
    <AccountContainer
      title={t(`${prefix}.title`)}
      subTitle={t(`${prefix}.desc`)}
      hasLinkUndercard
      wrapped
    >
      <Row>
        <Col xs={12} className="pt-2">
          <form onSubmit={handleSubmit(handleSubmitRecovery)}>
            <Row>
              <Col xs={12}>
                <TextInput
                  name="password"
                  control={control}
                  type="password"
                  label={t('common.terms.password')}
                  tooltip={msgValidations.invalidPassword}
                  rules={{
                    validate: {
                      notEmpty: validateTextNotEmpty,
                      password: validatePassword,
                    },
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <TextInput
                  name="passwordConfirm"
                  control={control}
                  type="password"
                  label={t('common.terms.passwordConfirm')}
                  rules={{
                    validate: {
                      notEmpty: validateTextNotEmpty,
                      password: validateRepeatPassword(password),
                    },
                  }}
                />
              </Col>
            </Row>
            <div className="py-4">
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
                text={t(`common.actions.restore`)}
                fullwidth
              />
            </div>
          </form>
        </Col>
      </Row>
    </AccountContainer>
  );
}
